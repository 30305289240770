import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import { Button, Image, Input, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import '../../../src/global.css';
import Header from '../../components/Header';
import './style.css';

import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { INoticiasDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useNoticiasService } from '../../services/noticias.service';
import { useNotificationService } from '../../services/notification.service';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { token } from '../../util/store';

const NoticiasCreate: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();
	const noticiaService = useNoticiasService();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const [noticia, setNoticia] = useState<INoticiasDTO>();
	const [predicate] = useState<Predicate>(new Predicate());
	const { idNoticia } = useParams<any>();

	const findNoticia = () => {
		setIsGlobalLoading(true);
		noticiaService
			.findById({ id: idNoticia })
			.then((response) => {
				setNoticia(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		if (idNoticia) {
			findNoticia();
		}
		return;
	}, []);
	const submit = () => {
		setIsGlobalLoading(true);
		if (!idNoticia) {
			noticiaService
				.create(noticia)
				.then(() => {
					notification({
						description: `Noticia cadastrada com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/noticias');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		} else {
			noticiaService
				.patch(noticia)
				.then(() => {
					notification({
						description: `Noticia editada com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/noticias');
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const submitValidationFields = () => {
		if (!noticia?.titulo) {
			notification({
				description: `Por favor, preencha o campo titulo da notícia.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!noticia?.descricao) {
			notification({
				description: `Por favor, preencha o campo descricao da notícia.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!noticia?.link) {
			notification({
				description: `Por favor, preencha o campo link da notícia.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!noticia?.imagem) {
			notification({
				description: `Por favor, selecione uma imagem para a notícia corretamente.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}

		submit();
	};

	const onChange = ({ file }: UploadChangeParam) => {
		const { status } = file;

		if (status === 'done') {
			notification({
				description: `${file.name} upload feito com sucesso!`,
				type: 'success',
				message: 'Sucesso!',
			});
			const response = file.response;
			setNoticia({ ...noticia, imagem: response });
		} else if (status === 'error') {
			notification({
				description: `${file.name} falha ao fazer o upload do arquivo`,
				type: 'error',
				message: 'Erro!',
			});
		} else if (status === 'removed') {
			setNoticia({ ...noticia, imagem: null });
			notification({
				description: `${file.name} removido com sucesso!`,
				type: 'success',
				message: 'Sucesso!',
			});
		}
	};

	const beforeUpload = (file: UploadFile) => {
		const isValid =
			file !== undefined && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/x-icon' || file.type === 'image/webp');
		if (!isValid) {
			file.status = 'error';
			notification({
				description: `Apenas arquivos PNG, JPG, WEBP e ICO são permitidos.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}
		if (file.size > 25 * 1024 * 1024) {
			file.status = 'error';
			notification({
				description: `O tamanho do arquivo deve ser inferior a 25MB.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}
		return isValid;
	};

	return (
		<div className="container-fondo">
			<Header />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row">
					<div className="card-form">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira informações para {idNoticia ? 'edição' : 'cadastro'} de notícias
							</h3>
							<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
						</div>
						<div className="input-box">
							<label htmlFor="title">Título da notícia</label>
							<Input
								defaultValue={noticia?.titulo}
								value={noticia?.titulo}
								id="title"
								maxLength={100}
								placeholder="Ex.: Novo lançamento de produto"
								onChange={(e) => setNoticia({ ...noticia, titulo: e.target.value })}
							/>
						</div>
						<div className="input-box">
							<label htmlFor="Description">Descrição da notícia</label>
							<Input
								defaultValue={noticia?.descricao}
								value={noticia?.descricao}
								id="Description"
								maxLength={100}
								placeholder="Ex.: Descreva o lançamento e suas funcionalidades"
								onChange={(e) => setNoticia({ ...noticia, descricao: e.target.value })}
							/>
						</div>
						<div className="input-box">
							<label htmlFor="link">Link da notícia</label>
							<Input
								defaultValue={noticia?.link}
								value={noticia?.link}
								id="link"
								maxLength={400}
								placeholder="x.: https://www.exemplo.com/noticia"
								onChange={(e) => setNoticia({ ...noticia, link: e.target.value })}
							/>
						</div>
						<div className="input-box" style={{ minWidth: '100%', minHeight: '100px' }}>
							<label>Imagem da notícia</label>
							<div style={{ textAlign: 'center', width: '100%' }}>
								<div>{noticia?.imagem && <Image src={noticia?.imagem} width={200} alt="imagem da noticia" />}</div>
								<Upload
									headers={{ Authorization: `Bearer ${token()}` }}
									action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload/link`}
									onChange={onChange}
									maxCount={1}
									beforeUpload={beforeUpload}
								>
									<Button
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											marginBottom: '1rem',
										}}
									>
										<UploadOutlined style={{ fontSize: '20px', marginBottom: '4px' }} />
										<span>Clique para fazer upload da imagem</span>
									</Button>
								</Upload>
							</div>
						</div>
						<div className="botoes" style={{ marginTop: 10 }}>
							<Link to={`/noticias`}>
								<Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
									Voltar
								</Button>
							</Link>
							<Button style={{ width: 345 }} type="primary" id="proximo" className="button-primary" onClick={submitValidationFields}>
								Salvar
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/noticias`}
			/>
		</div>
	);
};

export default NoticiasCreate;
