import { CloseOutlined } from '@mui/icons-material';
import { Button, Input, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import '../../../src/global.css';
import Header from '../../components/Header';
import './style.css';

import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { INegocioCleanDTO, ITipoConteudoDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useNotificationService } from '../../services/notification.service';
import { useTipoConteudoService } from '../../services/tipo-conteudo.service';

const TipoConteudoCreate: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();
	const tipoconteudoSrvice = useTipoConteudoService();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const [tipoConteudo, setTipoConteudo] = useState<ITipoConteudoDTO>();
	const [predicate] = useState<Predicate>(new Predicate());
	const { idTipoConteudo } = useParams<any>();
	const [negocios, setNegocios] = useState<INegocioCleanDTO[]>([]);

	const findTipoConteudo = () => {
		setIsGlobalLoading(true);
		tipoconteudoSrvice
			.findById({ id: idTipoConteudo })
			.then((response) => {
				setTipoConteudo(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		console.log(`idTipoConteudo:${idTipoConteudo}`);
		if (idTipoConteudo) {
			findTipoConteudo();
		}
		return;
	}, []);

	const submit = () => {
		setIsGlobalLoading(true);
		if (!idTipoConteudo) {
			tipoconteudoSrvice
				.create(tipoConteudo)
				.then(() => {
					notification({
						description: `Tipo conteudo cadastrado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/tipo-conteudo');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		} else {
			tipoconteudoSrvice
				.patch(tipoConteudo)
				.then(() => {
					notification({
						description: `Tipo conteudo editado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/tipo-conteudo');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const submitValidationFields = () => {
		if (!tipoConteudo.descricao) {
			notification({
				description: `Por favor, preencha o campo nome do tipo conteúdo.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		submit();
	};

	return (
		<div className="container-fondo">
			<Header />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row">
					<div className="card-form">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira informações para {idTipoConteudo ? 'edição' : 'criação'} de tipo de conteúdo
							</h3>
							<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
						</div>
						<div className="input-box">
							<label>Nome do tipo de conteúdo</label>
							<Input
								defaultValue={tipoConteudo?.descricao}
								value={tipoConteudo?.descricao}
								maxLength={100}
								placeholder="Ex.: AULA"
								onChange={(e) =>
									setTipoConteudo({
										...tipoConteudo,
										descricao: e.target.value,
									})
								}
							/>
						</div>
						<div className="input-box">
							<label>Recompensa (coins)</label>
							<InputNumber
								defaultValue={tipoConteudo?.coins}
								value={tipoConteudo?.coins}
								placeholder="Ex.: 100"
								max={10000}
								min={0}
								onChange={(e) =>
									setTipoConteudo({
										...tipoConteudo,
										coins: e,
									})
								}
							/>
						</div>
						<div className="botoes" style={{ marginTop: 10 }}>
							<Link to={`/tipo-conteudo`}>
								<Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
									Voltar
								</Button>
							</Link>
							<Button style={{ width: 345 }} type="primary" id="proximo" className="button-primary" onClick={submitValidationFields}>
								Salvar
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/tipo-conteudo`}
			/>
		</div>
	);
};

export default TipoConteudoCreate;
