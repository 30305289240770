import { ICobrancaPagarMeDTO } from '../../models/happy-code-api.model';
import { notification } from 'antd';
import Style from './stylePagamento.module.css'
import statusMessage from './messages';


interface Props {
    cobranca: ICobrancaPagarMeDTO;
}
const Pagamento = ({cobranca}: Props) => {
    
    const copiarLink = async () => {
        navigator.clipboard.writeText(cobranca.qrCode);
        notification.success({ description: "", message: 'Qr Code Copiado' });
    }
    let timeout:NodeJS.Timeout; 
     const paymentType = () => {     
     
        const renderPix = () => {
            switch (cobranca?.statusCobranca) {
            case "GRD":
                timeout = setTimeout(() => {
                    paymentType();
                }, 1000);
                return (
                <div className={Style.container_any}>
                    <p>Cobrança Gerada!</p>
                    <p>Escaneie o QR Code para efetuar o pagamento</p>
                    <img src={cobranca?.url} />
                    <button onClick={copiarLink}>Copiar QR Code</button>
                </div>
                )
            case "PGO":
                clearTimeout(timeout);
                return(
                statusMessage.PGO.message
                )
            case "CAN":
                clearTimeout(timeout);
                return(
                statusMessage.CAN.message
                )
            case "IST":
                clearTimeout(timeout);
                return(
                statusMessage.IST.message
                )
             case "RPC":     
                clearTimeout(timeout);
                return(
                statusMessage.RPC.message
                )               
            default:
                break;
          }
        };
    
        const renderCartao = () => {
            switch (cobranca?.statusCobranca) {
                case "GRD":
                    timeout = setTimeout(() => {
                        paymentType();
                    }, 1000);
                    return (
                     <div className={Style.container_any}>      
                     <p>Obrigado!</p>
                      <p>Seu pagamento está sendo processado<br />Em caso de dúvidas, contate seu HUB.</p>
                  </div>
                    )
                case "PGO":
                    clearTimeout(timeout);
                    return(
                    statusMessage.PGO.message
                    )
                case "CAN":
                    clearTimeout(timeout);
                    return(
                   statusMessage.CAN.message
                    )
                case "IST":
                    clearTimeout(timeout);
                    return(
                    statusMessage.IST.message
                    )      
                case "RPC":     
                clearTimeout(timeout);
                return(
                statusMessage.RPC.message
                )                             
             default:
                    break;
              }
        }
    
        const renderBoleto = () => (
            <div className={Style.container_any} style={{transform:'translateY(0%)'}}>
                <p>Obrigado!</p>
                <p>Seu pagamento será confirmado em até 3 dias<br></br>Em caso de dúvidas, contate seu HUB.</p>
                {process.env.REACT_APP_HAPPYCODE_PUBLIC_URL_BOLETO 
                ? <div className={Style.container_boleto}><iframe src={process.env.REACT_APP_HAPPYCODE_PUBLIC_URL_BOLETO} width={'100%'} height={'100%'} title="Boleto" /></div>
                : <div className={Style.container_boleto}>
                    <iframe src={cobranca?.url} width={'100%'} height={'100%'} title="Boleto" />
                </div> }  
                 <a style={{marginTop:'0.5rem'}} href={cobranca?.url} target='_blank' rel='noreferrer'>Ou clique aqui para abrir o Link do Boleto</a>     
            </div>
        );

        const renderMqf = () => {
            switch (cobranca?.statusCobranca) {
                case "GRD":
                    timeout = setTimeout(() => {
                        paymentType();
                    }, 1000);
                    return (
                  <div className={Style.container_any}>      
                     <p>Obrigado!</p>
                     <p>verifique a máquina fisica de cartão para <br />proceder com o pagamento</p>
                     <p>em caso de dúvidas, contate seu HUB</p>
                  </div>
                    )
                case "PGO":
                    clearTimeout(timeout);
                    return(
                   statusMessage.PGO.message
                    )
                case "CAN":
                    clearTimeout(timeout);
                    return(
                   statusMessage.CAN.message
                    )
                case "IST":
                    clearTimeout(timeout);
                    return(
                    statusMessage.IST.message
                    )      
                case "RPC":     
                    clearTimeout(timeout);
                     return(
					statusMessage.RPC.message				
                )                         
                default:
                    break;
              }
        }

        // varre o tipo de pagamento e retorna a função correta
        switch (cobranca?.tipoPagamento?.codigo) {
            case 'PIX':
                return renderPix();
            case 'CRC':
                return renderCartao();
            case 'CRR':
                return renderCartao();
            case 'BOL':
                return renderBoleto();
            case 'PMF':
                return renderMqf();    
            default:
                break;
        }
    }
      return (
    <div className={Style.container_pagamento}>
            {paymentType()}
    </div>
  )
}

export default Pagamento