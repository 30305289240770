import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import { IStatusENotas, IRelatorioNotas } from '../models/relatorio.model';

export const useEnotasService = () => {
	const resourceURL = 'enotas';

	const relatorioNotasPorHub = (
		empresaId: string, page: number,status: IStatusENotas, dataVencimentoInicio: string, dataVencimentoFim: string
	): Promise<AxiosResponse<IRelatorioNotas>> =>
		http.get<IRelatorioNotas>(
			resourceURL + `/${empresaId}/${page}/${status}/?dataVencimentoInicio=${dataVencimentoInicio}&dataVencimentoFim=${dataVencimentoFim}`
		);

	const relatorioNotasSede = (
		empresaId: string, page: number, status: IStatusENotas
	): Promise<AxiosResponse<IRelatorioNotas>> =>
		http.get<IRelatorioNotas>(
			resourceURL + `/sede/${empresaId}/${page}/${status}`
		);

	const downloadPdf = (
		empresaId: string, notaId: string
	): Promise<AxiosResponse<any>> =>
		http.get<any>(resourceURL + `/pdf?empresaId=${empresaId}&nfeId=${notaId}`)

	const buscarNotaProduto = (
		cobrancaId: number
	): Promise<AxiosResponse<any>> =>
		http.post<any>(resourceURL + `/nota-produto/?cobrancaId=${cobrancaId}`);

	const buscarNotaServico = (
		cobrancaId: number
	): Promise<AxiosResponse<any>> =>
		http.post<any>(resourceURL + `/nota-servico/?cobrancaId=${cobrancaId}`);

	const downloadXml = (
		empresaId: string, notaId: string
	): Promise<AxiosResponse<any>> =>
		http.get<any>(resourceURL + `/xml?empresaId=${empresaId}&nfeId=${notaId}`)

	const findRotinaEnvioEmailNfsXml = (
		to: string,
		cc: string,
		dataInicio,
		dataFim
	): Promise<AxiosResponse<void>> =>
		http.post<void>(resourceURL + `/xml-mensal-data/${to}/${cc}/${dataInicio}/${dataFim}`);

	return { 
		relatorioNotasPorHub,
		relatorioNotasSede,
		downloadPdf,
		downloadXml,
		buscarNotaProduto,
		buscarNotaServico,
		findRotinaEnvioEmailNfsXml
	  };
};