import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { IBrasaoDTO } from '../../models/happy-code-api.model';
import { useBrasaoService } from '../../services/brasao.service';
import { useNotificationService } from '../../services/notification.service';

interface TItemLista {
	brasao: IBrasaoDTO;
}

const ItemListBrasao = memo(({ brasao }: TItemLista) => {
	const [ativo, setAtivo] = useState<boolean>();

	const notification = useNotificationService();
	const history = useHistory();
	const brasaoService = useBrasaoService();
	const { setIsGlobalLoading } = useGlobalContext();

	const goTo = () => {
		history.push(`brasao/${brasao?.id}/create`);
	};

	useEffect(() => {
		setAtivo(brasao?.ativo);
	}, [brasao?.ativo]);

	const showDeleteConfirm = () => {
		Modal.confirm({
			title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} o brasão?`,
			icon: <ExclamationCircleTwoTone rev={undefined} />,
			content: brasao.nome,
			okText: 'Sim',
			okType: `${ativo ? 'danger' : 'primary'}`,
			cancelText: 'Não',
			onOk: () =>
				brasaoService
					.patch({ id: brasao.id, ativo: !ativo })
					.then(() => {
						setAtivo(!ativo);
						notification({
							description: 'Brasão alterado com sucesso!',
							type: 'success',
							message: 'Sucesso',
						});
					})
					.finally(() => setIsGlobalLoading(false)),
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onCancel: () => {},
		});
	};
	const menu = (
		<Menu>
			<Menu.Item onClick={showDeleteConfirm}>
				<a rel="noopener noreferrer">Suspender/Ativar</a>
			</Menu.Item>
		</Menu>
	);

	return (
		<div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
			<div className="user" id="cursos" style={{ width: '20%' }}>
				<div className="nome">
					<p>{brasao?.id}</p>
					{ativo ? (
						<div>
							<div className="estado-user">
								<div className="dot-verde"></div>
								<p className="ativo">Ativo</p>
							</div>
						</div>
					) : (
						<div>
							<div className="estado-user">
								<div className="dot-vermelho"></div>
								<p className="inativo">Inativo</p>
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="funcao-user" id="conteudo-id" style={{ width: '46%', textAlign: 'center' }}>
				<p>{brasao?.nome}</p>
			</div>
			<div className="funcao-user" id="conteudo-id" style={{ width: '20%' }}>
				<p>{brasao?.coins}</p>
			</div>

			<div className="funcao-user" id="cursos-modalidade" style={{ width: '20%' }}>
				<p>{brasao?.insignia}</p>
			</div>
			{/* <div className="funcao-user" id="cursos-id" style={{ width: '25%' }}>
			<p>img123456</p>
		</div> */}
			{/* <div className="funcao-user" id="cursos-id" style={{ width: '14%' }}>
			<p>teste</p>
		</div> */}
			{/* <div className="funcao-user" id="cursos-id" style={{ width: '10%' }}>
			<p>2024-06-03 13:00</p>
		</div> */}
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '10%' }}>
				{/* <p>2024-08-03</p> */}
			</div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '5%' }}>
				<Dropdown overlay={menu} placement="bottomRight" arrow>
					<Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
				</Dropdown>
			</div>
			<div className="registro-opcao" style={{ width: '5%' }} onClick={goTo}>
				<i className="row-user__action-cell">
					<Unicons.UilAngleRight size="32" className="action-cell__action-icon" />
				</i>
			</div>
		</div>
	);
});

ItemListBrasao.displayName = 'ItemListBrasao';

export default ItemListBrasao;
