import { useAlunoContext } from '../../../context/AlunoContext';
import { useGlobalContext } from '../../../context/GlobalContext';
import { IEnderecoCleanDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { useLogradouroService } from '../../../services/logradouro.service';
import { useNotificationService } from '../../../services/notification.service';

const useEndereco = () => {
    const { aluno, setAluno } = useAlunoContext();
    const { setIsGlobalLoading } = useGlobalContext();
    const logradouroService = useLogradouroService();
    const notification = useNotificationService();

    const onCepChange = (cep, index) => {
        if (!aluno.pessoa.pessoaResponsaveis[index].responsavel.endereco) {
            aluno.pessoa.pessoaResponsaveis[index].responsavel.endereco = {} as IEnderecoCleanDTO;
        }

        aluno.pessoa.pessoaResponsaveis[index].responsavel.endereco.cep = cep;
        setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: [...aluno.pessoa.pessoaResponsaveis] } });

        if (cep.length !== 8) {
            return;
        }

        setIsGlobalLoading(true);

        const predicateLogradouro = new Predicate(10);
        predicateLogradouro.addOption('cep', cep);

        logradouroService.findCustom(predicateLogradouro)
            .then(({ data }) => {
                if (data && data.length > 0) {
                    const logradouro = data[0];
                    const endereco = { ...aluno.pessoa.pessoaResponsaveis[index].responsavel.endereco };

                    endereco.cep = logradouro.cep;
                    endereco.bairro = logradouro.bairroNome;
                    endereco.cidade = logradouro.cidadeNome;
                    endereco.logradouro = logradouro.nomeCompleto;
                    endereco.nomeUF = logradouro.cidadeEstadoNome;
                    endereco.uf = logradouro.cidadeEstadoCodigo;
                    endereco.paisId = logradouro.cidadeEstadoPaisId;

                    aluno.pessoa.pessoaResponsaveis[index].responsavel.endereco = endereco;
                    setAluno({ ...aluno, pessoa: { ...aluno.pessoa, pessoaResponsaveis: [...aluno.pessoa.pessoaResponsaveis] } });
                } else {
                    notification({
                        description: 'CEP não encontrado',
                        type: 'warning',
                        message: 'Aviso',
                    });
                }
            })
            .catch((error) => {
                console.error("Erro ao buscar logradouro:", error);
                notification({
                    description: 'Erro ao buscar o logradouro. Tente novamente mais tarde.',
                    type: 'error',
                    message: 'Erro',
                });
            })
            .finally(() => setIsGlobalLoading(false));
    };

    return { onCepChange };
};

export default useEndereco;