import http from '../helpers/http-common';
import { IQuizCleanDTO, IQuizDTO, IQuizFullDTO } from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useQuizService = () => {
	const resourceURL = '/learn/v1/tbquiz';

	return {
		...baseFind<IQuizCleanDTO, IQuizDTO, IQuizFullDTO>(resourceURL),
		...baseFindList<IQuizCleanDTO, IQuizDTO, IQuizFullDTO>(resourceURL),
		...baseFindById<IQuizCleanDTO, IQuizDTO, IQuizFullDTO>(resourceURL),
		...baseCreate<IQuizCleanDTO, IQuizDTO, IQuizFullDTO>(resourceURL),
		...baseUpdate<IQuizCleanDTO, IQuizDTO, IQuizFullDTO>(resourceURL),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<IQuizCleanDTO>> =>
			http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IQuizCleanDTO>> =>
			http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
	};
};
