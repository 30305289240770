import { CloseOutlined } from '@mui/icons-material';
import { Button, Input, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import '../../../src/global.css';
import Header from '../../components/Header';
import './style.css';

import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import {
	ICompetenciaLearnCleanDTO,
	IConteudoLearnCustomDTO,
	ICurriculoLearnDTO,
	ICurriculoLearnFullDTO,
	IHabilidadeLearnCleanDTO,
	IModuloLearnDTO,
	INegocioLearnCleanDTO,
	ITipoConteudoCleanDTO,
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useCompenteciaService } from '../../services/competencias.service';
import { useConteudoService } from '../../services/conteudo.service';
import { useCurriculoLearnService } from '../../services/curriculo-learn.service';
import { useHabilidadeService } from '../../services/habilidades.service';
import { useModuloLearnService } from '../../services/moduloLearn.service';
import { useNegocioLearnService } from '../../services/negocio-learn.service';
import { useNotificationService } from '../../services/notification.service';
import { useTipoConteudoService } from '../../services/tipo-conteudo.service';

const ConteudoCreate: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();
	const conteudoService = useConteudoService();
	const negocioService = useNegocioLearnService();
	const moduloService = useModuloLearnService();
	const curriculoService = useCurriculoLearnService();
	const tipoConteudoService = useTipoConteudoService();
	const habilidadeService = useHabilidadeService();
	const competenciaService = useCompenteciaService();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const [conteudo, setConteudo] = useState<IConteudoLearnCustomDTO>();
	const [habilidades, setHabilidade] = useState<IHabilidadeLearnCleanDTO[]>();
	const [competencias, setCompetencia] = useState<ICompetenciaLearnCleanDTO[]>();
	const [predicate] = useState<Predicate>(new Predicate());
	const { idConteudo } = useParams<any>();
	const [negocios, setNegocios] = useState<INegocioLearnCleanDTO[]>([]);
	const [modulos, setModulos] = useState<IModuloLearnDTO[]>([]);
	const [curriculos, setCurriculos] = useState<ICurriculoLearnDTO[]>();
	const [tipoConteudo, setTipoConteudo] = useState<ITipoConteudoCleanDTO[]>();

	const findConteudo = () => {
		setIsGlobalLoading(true);
		conteudoService
			.findbyIdCustom(idConteudo)
			.then((response) => {
				setConteudo(response.data);
				console.log(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findNegocio = () => {
		setIsGlobalLoading(true);
		negocioService
			.findList(predicate)
			.then((response) => {
				setNegocios(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findModulo = () => {
		setIsGlobalLoading(true);
		moduloService
			.findList(predicate)
			.then((response) => {
				setModulos(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findCurriculo = () => {
		setIsGlobalLoading(true);
		curriculoService
			.findList(predicate)
			.then((response) => {
				setCurriculos(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findTipoConteudo = () => {
		setIsGlobalLoading(true);
		tipoConteudoService
			.findList(predicate)
			.then((response) => {
				setTipoConteudo(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findHabilidade = () => {
		setIsGlobalLoading(true);
		habilidadeService
			.findList(predicate)
			.then((response) => {
				setHabilidade(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findCompetencia = () => {
		setIsGlobalLoading(true);
		competenciaService
			.findList(predicate)
			.then((response) => {
				setCompetencia(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		findModulo();
		findNegocio();
		findCurriculo();
		findTipoConteudo();
		findHabilidade();
		findCompetencia();
		if (idConteudo) {
			findConteudo();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submit = () => {
		setIsGlobalLoading(true);
		if (!idConteudo) {
			console.log(conteudo);
			conteudoService
				.createCustom(conteudo)
				.then(() => {
					notification({
						description: `Conteúdo cadastrado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/conteudos');
				})
				.finally(() => setIsGlobalLoading(false));
		} else {
			conteudoService
				.patchCustom(conteudo)
				.then(() => {
					notification({
						description: `Conteúdo editado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/conteudos');
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const submitValidationFields = () => {
		if (!conteudo?.conteudo) {
			notification({
				description: `Por favor, preencha o campo Título do Conteúdo.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}

		if (!conteudo?.modulo?.id) {
			notification({
				description: `Por favor, preencha o campo Módulo.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!conteudo?.negocio?.id) {
			notification({
				description: `Por favor, preencha o campo Negócio.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!conteudo?.curriculo?.id) {
			notification({
				description: `Por favor, preencha o campo Currículo.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!conteudo?.tipoConteudo.id) {
			notification({
				description: `Por favor, preencha o campo Tipo do Conteúdo.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		submit();
	};

	const onChangeHabilidades = (e: any) => {
		if (e) {
			setConteudo({
				...conteudo,
				habilidades: e.map((habilidade: any) => {
					return { id: habilidade };
				}),
			});
		}
	};
	const onChangeCompetencias = (e: any) => {
		if (e) {
			setConteudo({
				...conteudo,
				competencias: e.map((competencia: any) => {
					return { id: competencia };
				}),
			});
		}
	};

	return (
		<div className="container-fondo">
			<Header />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row">
					<div className="card-form">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira informações para {idConteudo ? 'edição' : 'criação'} de conteúdo
							</h3>
							<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
						</div>

						<div className="input-box">
							<label>Habilidades</label>
							<Select
								mode="multiple"
								placeholder="Selecione"
								style={{ width: '100%' }}
								allowClear
								value={conteudo?.habilidades?.map((habilidade) => habilidade.id)}
								onChange={(e) => onChangeHabilidades(e)}
							>
								{habilidades &&
									habilidades.map((habilidade) => (
										<Select.Option key={habilidade.id} value={habilidade.id}>
											{habilidade.descricao}
										</Select.Option>
									))}
							</Select>
						</div>

						<div className="input-box">
							<label>Competências</label>
							<Select
								mode="multiple"
								placeholder="Selecione"
								style={{ width: '100%' }}
								allowClear
								value={conteudo?.competencias?.map((competencia) => competencia.id)}
								onChange={(e) => onChangeCompetencias(e)}
							>
								{competencias &&
									competencias.map((competencia) => (
										<Select.Option key={competencia.id} value={competencia.id}>
											{competencia.descricao}
										</Select.Option>
									))}
							</Select>
						</div>
						<div className="input-box">
							<label>Negócio</label>
							<Select
								style={{ width: '100%' }}
								placeholder="Selecione"
								allowClear
								defaultValue={conteudo?.negocio?.id}
								value={conteudo?.negocio?.id}
								onChange={(e) =>
									setConteudo({
										...conteudo,
										negocio: { ...conteudo?.negocio, id: e },
									})
								}
							>
								{negocios &&
									negocios.map((negocio) => (
										<Select.Option key={negocio.id} value={negocio.id} text={negocio.descricao}>
											{negocio.descricao}
										</Select.Option>
									))}
							</Select>
						</div>
						<div className="input-box">
							<label>Currículo</label>
							<Select
								allowClear
								style={{ width: '100%' }}
								placeholder="Selecione"
								defaultValue={conteudo?.curriculo?.id}
								value={conteudo?.curriculo?.id}
								onChange={(e) =>
									setConteudo({
										...conteudo,
										curriculo: { ...conteudo?.curriculo, id: e },
									})
								}
							>
								{curriculos?.map((curriculo) => (
									<Select.Option key={curriculo.id} value={curriculo.id} text={curriculo.nome}>
										{curriculo.nome}
									</Select.Option>
								))}
							</Select>
						</div>
						<div className="input-box">
							<label>Módulo</label>
							<Select
								allowClear
								placeholder="Selecione"
								style={{ width: '100%' }}
								defaultValue={conteudo?.modulo?.id}
								value={conteudo?.modulo?.id}
								onChange={(e) =>
									conteudo &&
									setConteudo({
										...conteudo,
										modulo: { ...conteudo?.modulo, id: e },
									})
								}
							>
								{modulos &&
									modulos.map((modulo) => (
										<Select.Option key={modulo.id} value={modulo.id}>
											{modulo.descricao}
										</Select.Option>
									))}
							</Select>
						</div>
						<div className="input-box">
							<label>Tipo do conteúdo</label>
							<Select
								allowClear
								style={{ width: '100%' }}
								id="curso"
								placeholder="Selecione"
								value={conteudo?.tipoConteudo?.id}
								defaultValue={conteudo?.tipoConteudo?.id}
								onChange={(e) =>
									setConteudo({
										...conteudo,
										tipoConteudo: { ...conteudo?.tipoConteudo, id: e },
									})
								}
							>
								{tipoConteudo?.map((tipoConteudo) => (
									<Select.Option key={tipoConteudo.id} value={tipoConteudo.id}>
										{tipoConteudo.descricao}
									</Select.Option>
								))}
							</Select>
						</div>
						<div className="input-box">
							<label>Numero da ordem</label>
							<InputNumber
								value={idConteudo && conteudo?.nrOrdem}
								defaultValue={idConteudo && conteudo?.nrOrdem}
								id="NrOrdem"
								placeholder="1"
								maxLength={100}
								min={0}
								onChange={(e) =>
									setConteudo({
										...conteudo,
										nrOrdem: e,
									})
								}
							/>
						</div>
						<div className="input-box">
							<label>Título do conteúdo / Projeto</label>
							<Input
								defaultValue={idConteudo && conteudo?.conteudo}
								value={idConteudo && conteudo?.conteudo}
								id="DsConteudo"
								placeholder="Ex.:Desenvolver um game similar a um GPS"
								maxLength={100}
								onChange={(e) => setConteudo({ ...conteudo, conteudo: e.target.value })}
							/>
						</div>
						<div className="input-box">
							<label>Descrição do conteúdo / Projeto</label>
							<Input
								value={idConteudo && conteudo?.projeto}
								defaultValue={idConteudo && conteudo?.projeto}
								id="DsConteudo"
								placeholder="Opcional"
								maxLength={100}
								onChange={(e) =>
									setConteudo({
										...conteudo,
										projeto: e.target.value,
									})
								}
							/>
						</div>

						<div className="botoes" style={{ marginTop: 10 }}>
							<Link to={`/conteudos`}>
								<Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
									Voltar
								</Button>
							</Link>
							<Button style={{ width: 345 }} type="primary" id="proximo" className="button-primary" onClick={submitValidationFields}>
								Salvar
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/conteudos`}
			/>
		</div>
	);
};

export default ConteudoCreate;
