import { Fragment } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import useGetListOutrasMatriculas from '../../globalHooks/matricula/use-get-list-outras-matriculas.hook';
import { Schedule } from '../../interfaces/offer/schedule.interface';
import { ITurmaCustomDTO } from '../../models/happy-code-api.model';
import { formatHora } from '../../util/format';
import { horarioDaGrade } from './funcoes';
import './style.css';
import { CalendarBlocks } from './sub-components/calendar-blocks.components';
import { LunchBox } from './sub-components/lunch-box';
import useCalendar from './use-calendar.hook';

interface CalendarOfferProps {
	turmaSemana: ITurmaCustomDTO;
	telaTurma?: boolean;
}

export const CalendarOffer = ({ turmaSemana, telaTurma }: CalendarOfferProps) => {
	const { unidade, tipoUnidade } = useGlobalContext();

	const { currentContractEnrollments } = useCalendar();
	const { otherEnrollmentsData } = useGetListOutrasMatriculas();

	function subtrair15Minutos(hora) {
		const horarioDate = new Date(`2023-09-14T${hora}`);
		horarioDate.setMinutes(horarioDate.getMinutes() - 15);
		return horarioDate.toLocaleTimeString([], {
			hour: '2-digit',
			minute: '2-digit',
		});
	}

	const criarGradeAPartirDaPrimeiraHoraDaFranquia = (): Schedule[] => {
		if (!unidade?.horaAulaInicio || !unidade?.horaFechamento) return [];

		const primeiroHorario = formatHora(unidade.horaAulaInicio);
		const horaFechamentoHub = formatHora(unidade.horaFechamento);

		return horarioDaGrade(primeiroHorario, horaFechamentoHub, tipoUnidade?.id === 1);
	};

	const dias = ['-', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

	return (
		<div className="calendar">
			{dias.map((dia, index) => (
				<div key={index} className="box-in-calendar d-flex-row-center-center">
					<p>{dia}</p>
				</div>
			))}

			{criarGradeAPartirDaPrimeiraHoraDaFranquia()
				.filter(({ horaInicial }) => horaInicial)
				.map((grade, index) => (
					<Fragment key={index}>
						<CalendarBlocks
							turmaSemana={turmaSemana}
							grade={grade}
							telaTurma={telaTurma}
							idRow={index}
							currentContractEnrollments={currentContractEnrollments}
							otherEnrollmentsData={otherEnrollmentsData}
						/>
						{grade.horaFinal === subtrair15Minutos(unidade.horaAlmocoInicio) && <LunchBox />}
					</Fragment>
				))}
		</div>
	);
};
