import { useMemo } from "react";
import { IRelFinanceiroDetalhado } from "../../../../../models/relatorio.model";
import { RelFinanceiroDetalhadoTotalColumnKeys } from "../../../../../models/types.model";
import { formatDataRelatorio } from "../../../../../util/format";


interface UseDadosExportExcelProps {
    data: IRelFinanceiroDetalhado[];
    totalColumns: RelFinanceiroDetalhadoTotalColumnKeys[];
    getColumnTotal: (key: RelFinanceiroDetalhadoTotalColumnKeys) => string;
}

export const useDadosExportExcel = ({ data, totalColumns, getColumnTotal }: UseDadosExportExcelProps) => {
    return useMemo(() => {
        const arrayDados = [];
        const totals: Record<string, string> = {};

        totalColumns.forEach((key) => {
            totals[key] = getColumnTotal(key);
        });

        if (data) {
            data.forEach((dado) => {
                arrayDados.push({
                    C02_ALUNO: dado.C02_ALUNO,
                    C03_STATUS_ALUNO: dado.C03_STATUS_ALUNO,
                    C04_RESPONSAVEL_FINANCEIRO: dado.C04_RESPONSAVEL_FINANCEIRO,
                    C05_NEGOCIO: dado.C05_NEGOCIO,
                    C06_CURRICULO: dado.C06_CURRICULO,
                    C07_TIPO_CURRICULO: dado.C07_TIPO_CURRICULO,
                    C08_TURMA: dado.C08_TURMA,
                    C09_ID_MATRICULA: dado.C09_ID_MATRICULA,
                    C10_STATUS_MATRICULA: dado.C10_STATUS_MATRICULA,
                    C11_ID_COBRANCA: dado.C11_ID_COBRANCA,
                    C12_ID_FINANCEIRO: dado.C12_ID_FINANCEIRO,
                    C13_STATUS_COBRANCA: dado.C13_STATUS_COBRANCA,
                    C14_STATUS_PARCELA: dado.C14_STATUS_PARCELA,
                    C15_TIPO_PAGAMENTO: dado.C15_TIPO_PAGAMENTO,
                    C16_PARCELAMENTO_CONTRATO: dado.C16_PARCELAMENTO_CONTRATO,
                    C17_PARCELA_ATUAL: dado.C17_PARCELA_ATUAL,
                    C18_VL_TOTAL_OFERTA: dado.C18_VL_TOTAL_OFERTA,
                    C19_VL_TOTAL_COM_DESCONTO: dado.C19_VL_TOTAL_COM_DESCONTO,
                    C20_VL_OFERTA_HUB: dado.C20_VL_OFERTA_HUB,
                    C21_VL_OFERTA_TEMPLO: dado.C21_VL_OFERTA_TEMPLO,
                    C22_VL_MENSALIDADE_COM_DESCONTO: dado.C22_VL_MENSALIDADE_COM_DESCONTO,
                    C23_VL_PARCELA: dado.C23_VL_PARCELA,
                    C24_VL_ENCARGOS: dado.C24_VL_ENCARGOS,
                    C25_VL_COBRANCA: dado.C25_VL_COBRANCA,
                    C26_VL_PAGO: dado.C26_VL_PAGO,
                    C27_VL_PARCELA_HUB: dado.C27_VL_PARCELA_HUB,
                    C28_VL_PARCELA_LICENCA_TEMPLO: dado.C28_VL_PARCELA_LICENCA_TEMPLO,
                    C29_DT_INICIO_CONTRATO: formatDataRelatorio(dado.C29_DT_INICIO_CONTRATO),
                    C30_DT_VENCIMENTO_PARCELA: formatDataRelatorio(dado.C30_DT_VENCIMENTO_PARCELA),
                    C31_DT_PAGAMENTO: formatDataRelatorio(dado.C31_DT_PAGAMENTO),
                    C32_DT_PREVISAO_RECEBIMENTO: formatDataRelatorio(dado.C32_DT_PREVISAO_RECEBIMENTO),
                    C33_DT_CANCELAMENTO: formatDataRelatorio(dado.C33_DT_CANCELAMENTO),
                    C34_EMITIU_NOTA: dado.C34_EMITIU_NOTA
                });
            });
        }

        const arrayNew = [...arrayDados, { ...totals }];

        return arrayNew;
    }, [data, totalColumns, getColumnTotal]);
};
