import { IMensagemHomeDTO } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';

export default function useMensagemHome() {
	const notification = useNotificationService();

	function uploadAdapter(loader) {
		return {
			upload: () => {
				return new Promise((resolve) => {
					loader.file.then((file) => {
						const reader = new FileReader();
						reader.onloadend = () => {
							const base64String = reader.result?.toString().split(',')[1] || '';
							resolve({ default: `data:image/png;base64,${base64String}` });
						};
						reader.readAsDataURL(file);
					});
				});
			},
		};
	}
	function uploadPlugin(editor) {
		editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
			return uploadAdapter(loader);
		};
	}
	const validationFields = (mensagemHome: IMensagemHomeDTO) => {
		if (!mensagemHome.dataVencimento) {
			notification({ type: 'warning', message: 'Atenção!', description: 'Data de vencimento do aviso é obrigatório!' });
			return false;
		}

		if (!mensagemHome.mensagem) {
			notification({ type: 'warning', message: 'Atenção!', description: 'A mensagem do aviso é obrigatória!' });

			return false;
		}
		if (!mensagemHome.titulo) {
			notification({ type: 'warning', message: 'Atenção!', description: 'O título do aviso é obrigatório!' });
			return false;
		}
		return true;
	};
	return {
		uploadPlugin,
		validationFields,
	};
}
