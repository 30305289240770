import { useAlunoContext } from "../../../context/AlunoContext";
import { useNotificationService } from "../../../services/notification.service";

const useResponsavelSelect = (redefinirPrincipal) => {
    const { aluno, setAluno } = useAlunoContext();
    const notification = useNotificationService();

    const handleResponsavelSelect = (responsavelSelected) => {

        if (aluno?.pessoa?.pessoaResponsaveis?.length < 3) {
            if (aluno?.pessoa?.pessoaResponsaveis?.length === 1 && !aluno?.pessoa?.pessoaResponsaveis[0].responsavel.nome && !aluno?.pessoa?.pessoaResponsaveis[0].responsavel.cpf) {
                aluno?.pessoa?.pessoaResponsaveis?.pop();
            }

            const novosResponsaveis = [
							{
								responsavel: { ...responsavelSelected },
								id: null,
								ordem: 0,
								principal: false,
								grauParentesco: '',
								ativo: true,
							},
							...aluno?.pessoa?.pessoaResponsaveis,
						];

            if (novosResponsaveis.length === 1) {
                novosResponsaveis[0].principal = true;
            }

            setAluno({
                ...aluno,
                pessoa: {
                    ...aluno.pessoa,
                    pessoaResponsaveis: novosResponsaveis
                }
            });

            redefinirPrincipal();
        } else {
            notification({
                description: 'Máximo 3 Responsáveis',
                type: 'warning',
                message: 'Aviso',
            });
        }
    };

    return { handleResponsavelSelect };
};

export default useResponsavelSelect;
