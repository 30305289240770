import { useMemo } from 'react';
import { IOperacoesPagarMeV4DTO } from '../../../../../models/relatorio.model';
import { formattedDate } from '../../../../../util/format';

interface UseDadosExportExcelProps {
    data: IOperacoesPagarMeV4DTO[];
}

export const useDadosExportExcel = ({ data }: UseDadosExportExcelProps) => {
    return useMemo(() => {
        const arrayDados = [];
        const totals: Record<string, string> = {};

        if (data) {
            data.forEach((dado) => {
                arrayDados.push({
                    C01_DATA_CRIACAO: dado.operacao.date_created,
                    C02_VALOR: dado.operacao.movement_object.amount - dado.operacao.movement_object.fee,
                    C03_TIPO_PAGAMENTO: dado.operacao.movement_object.payment_method,
                    C04_ALUNO: dado.nomeAluno,
                    C05_RESPONSAVEL_FINANCEIRO: dado.nomeResponsavel,
                    C06_CURRICULO: dado.dsCurso,
                    C07_DT_PAGAMENTO: formattedDate(dado.dhPagamento) || '00/00/000',
                    C08_VL_COBRANCA: dado.vlCobranca,
                    C09_VL_PAGO: dado.vlPago,
                    C10_VL_SEDE: dado.vlSede,
                    C11_VL_TAXA: dado.operacao.movement_object.fee,
                    C12_VL_FRANQUIA: dado.vlFranquia,
                    C13_VL_PARCELA: dado.parcela,
                    C14_OPERACAO: dado.operacao.type,
                    C15_OPERACAO_STATUS: dado.operacao.status,
                    C16_DATA_PREVISAO: formattedDate(dado.operacao.movement_object.funding_estimated_date) || '00/00/000',
                    C17_DATA_TRANSFERIDO: formattedDate(dado.operacao.movement_object.funding_date) || '00/00/000',
                    C18_ID_OPERACAO: dado.operacao.id,
                    C19_ID_COBRANCA: dado.cobrancaId,
                    C20_ID_SAQUE: dado.idSaque,
                    C21_OBJETO_DE_MOVIMENTO: dado.operacao.movement_object.object,
                    C22_STATUS_DO_MOVIMENTO: dado.operacao.movement_object.status,
                    C23_DATA_DE_CRIACAO_DO_MOVIMENTO: formattedDate(dado.operacao.movement_object.date_created) || '00/00/000',
                    C24_NOME_UNIDADE: dado.nomeUnidade,
                    C25_PARCELA_FINANCEIRO: dado.parcelaFinanceiro,
                    C26_UNICA: dado.flUnica,
                    C27_STATUS_DO_SAQUE: dado.statusSaque,
                });
            });
        }

        const arrayNew = [...arrayDados, { ...totals }];
        return arrayNew;
    }, [data]);
};
