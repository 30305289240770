import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tags } from '../../components/Tags';
import { useCursoContext } from '../../context/CursoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICursoDTO, INoticiasDTO } from '../../models/happy-code-api.model';
import { useCursoService } from '../../services/curso.service';
import { useNotificationService } from '../../services/notification.service';
import { useNoticiasService } from '../../services/noticias.service';

interface TItemLista {
	noticia?: INoticiasDTO;
}

const ItemListNoticia = memo(({ noticia }: TItemLista) => {
	const [ativo, setAtivo] = useState<boolean>();

	const notification = useNotificationService();
	const history = useHistory();
	const noticiaService = useNoticiasService();
	const { setIsGlobalLoading } = useGlobalContext();

	const goTo = () => {
		history.push(`/noticias/${noticia?.id}/create`);
	};

	useEffect(() => {
		setAtivo(noticia.ativo);
	}, [noticia.ativo]);

	const showDeleteConfirm = () => {
		Modal.confirm({
			title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} a notícia?`,
			icon: <ExclamationCircleTwoTone rev={undefined} />,
			content: noticia.titulo,
			okText: 'Sim',
			okType: `${ativo ? 'danger' : 'primary'}`,
			cancelText: 'Não',
			onOk: () =>
				noticiaService
					.patch({ id: noticia.id, ativo: !ativo })
					.then(() => {
						setAtivo(!ativo);
						notification({
							description: 'Notícia alterado com sucesso!',
							type: 'success',
							message: 'Sucesso',
						});
					})
					.finally(() => setIsGlobalLoading(false)),
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onCancel: () => {},
		});
	};

	const menu = (
		<Menu>
			<Menu.Item onClick={showDeleteConfirm}>
				<a rel="noopener noreferrer">Suspender/Ativar</a>
			</Menu.Item>
		</Menu>
	);

	return (
		<div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
			<div className="user" id="cursos" style={{ width: '10%' }}>
				<div className="nome">
					<p>{noticia?.id}</p>
					{ativo ? (
						<div>
							<div className="estado-user">
								<div className="dot-verde"></div>
								<p className="ativo">Ativo</p>
							</div>
						</div>
					) : (
						<div>
							<div className="estado-user">
								<div className="dot-vermelho"></div>
								<p className="inativo">Inativo</p>
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="user" id="cursos" style={{ width: '25%' }}>
				<div className="nome">
					<p>{noticia?.titulo}</p>
				</div>
			</div>
			<div className="funcao-user" id="conteudo-id" style={{ width: '35%' }}>
				<p>{noticia?.descricao}</p>
			</div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '20%' }}>
				<p>{noticia?.link}</p>
			</div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '10%' }}>
				<Dropdown overlay={menu} placement="bottomRight" arrow>
					<Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
				</Dropdown>
			</div>
			<div className="registro-opcao" style={{ width: '5%' }} onClick={goTo}>
				<i className="row-user__action-cell">
					<Unicons.UilAngleRight size="32" className="action-cell__action-icon" />
				</i>
			</div>
		</div>
	);
});
ItemListNoticia.displayName = 'ItemListNoticia';

export default ItemListNoticia;
