import { Form, Input, Modal, Radio, Select } from 'antd';
import 'antd/dist/antd.css';
import { isAfter } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { Auth } from '../../models/auth.model';
import { useAuthService } from '../../services/auth.service';
import { useCobrancaService } from '../../services/cobranca.service';
import { useNotificationService } from '../../services/notification.service';
import {
	formatMoney,
	formatPhone,
	isAfterDate,
	nowDateAddDays,
} from '../../util/format';
import ModalCustom from './modalCheckout/modal';
import useCheckoutState from './selectors/checkout.state';
// images
import creditCard from '../../assets/img/checkoutnew/credit-cards.png';
import icons from '../../assets/img/checkoutnew/icons-business.png';
import vector from '../../assets/img/checkoutnew/vector.png';
// Styles
import { Skeleton } from 'antd';
import { ContatoEnum } from '../../enum/contato.enum';
import { TipoStatusCobranca } from '../../enum/tipoStatusCobranca';
import CursosContratados from './cursos';
import statusMessage from './messages';
import Step1 from './step-1';
import Step3 from './step-3';
import Style from './style.module.css';
import Pagamento from './tela-pagamento';

const header = () => {
	return (
		<header
			className={Style.header}
			style={{ minHeight: window.innerWidth <= 968 ? '80px' : '125px' }}
		>
			<div className={Style.header_box}>
				<div>
					<h1>checkout de pagamento</h1>
					<img className={Style.icons_header} src={icons} alt="icons happy" />
				</div>
			</div>
		</header>
	);
};
function CheckoutNew() {
	const step = {
		DADOS: 0,
		PAGAMENTO: 1,
		REVISAO: 2,
		CONCLUSAO: 3,
		CANCELADO: 4,
	};
	const [current, setCurrent] = useState(step.DADOS);
	const [token, setToken] = useState<Auth>();
	const { cobranca, setCobranca, tiposPagamento } = useCheckoutState();
	const { id, idTipo } = useParams<any>();
	const cobrancaService = useCobrancaService();
	const authService = useAuthService();
	const notification = useNotificationService();
	const [isPrimeiraEmAberto, setIsPrimeiraEmAberto] = useState<boolean>(false);
	const [isCartaoCredito, setIsCartaoCredito] = useState<boolean>(false);
	const [isPgtoRecorrente, setIsPgtoRecorrente] = useState<boolean>(false);
	const [originalVencimento, setOriginalVencimento] = useState<Date | null>(null);
	const [tipoPagamentoSelecionado, setTipoPagamentoSelecionado] =
		useState<string>('null');
	const [parcela, setParcela] = useState<number>(0);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	const cel = cobranca?.contatos?.find(
		(contato) => contato?.tipoContatoCodigo === ContatoEnum.CEL_CODIGO
	)?.descricao;
	const tel = cobranca?.contatos?.find(
		(contato) => contato?.tipoContatoCodigo === ContatoEnum.TEL_CODIGO
	)?.descricao;

	const { Option } = Select;
	const { setIsGlobalLoading } = useGlobalContext();
	const [btnVisible, setBtnVisible] = useState(false);

	// valores formatados input sem mask
	const [formattedValue, setFormattedValue] = useState<string>('');
	const [formatteMonth, setFormatteMonth] = useState<string>('');
	const [formatteYear, setFormatteYear] = useState<string>('');
	const [formattedCvv, setFormattedCvv] = useState<string>('');

	useEffect(() => {
		const isencao = location.search.substring(1);

		setIsGlobalLoading(true);
		authService
			.basic()
			.then(({ data }) => {
				setToken(data);
				return data;
			})
			.then(async (responseToken) => {
				const { data } = await cobrancaService.findCheckout(
					id,
					isencao,
					responseToken.access_token
				);
				setLoading(false);
				if (data !== null) {
					const isCobrancaUnica = data?.cobrancaUnica;
					

					if (isAfterDate(data.dataVencimento)) {
						setOriginalVencimento(data.dataVencimento);
						data.dataVencimento = nowDateAddDays(0);
					} //vencido

					if (!data.cartao)
						data.cartao = {
							tipo: isCobrancaUnica ? 'CRR' : 'CRC',
							ano: null,
							bandeira: null,
							cvv: null,
							mes: null,
							nome: null,
							numero: null,
						};
					setIsPrimeiraEmAberto(data.primeiraCobrancaEmAberto);
					data.tipoPagamento = null;
					setCobranca(data);
					setParcela(data?.parcela);
				} else {
					notification({
						description: 'Cobrança não encontrada',
						type: 'warning',
						message: 'Aviso!',
					});
				}
			})
			.finally(() => setIsGlobalLoading(false));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (cobranca) {
			let descricao = '';

			try {
				descricao = tiposPagamento[tipoPagamentoSelecionado]?.dsPagamento;
			} catch (e) {
				descricao = tipoPagamentoSelecionado;
			}

			const { tipoPagamento, cartao, parcela } = cobranca;
			let tipoPagamentoCartao = null;
			let bandeira = null;
			let qtdParcelas = parcela;

			if (tipoPagamentoSelecionado === 'CRC') {
				tipoPagamentoCartao = tipoPagamentoSelecionado;
				bandeira = '';
				qtdParcelas = 1;
			} else if (tipoPagamentoSelecionado === 'CRR') {
				tipoPagamentoCartao = tipoPagamentoSelecionado;
				bandeira = '';
			}
			setCobranca({
				...cobranca,
				tipoPagamento: {
					...tipoPagamento,
					codigo: tipoPagamentoSelecionado,
					descricao,
				},
				cartao: { ...cartao, tipo: tipoPagamentoCartao, bandeira },
				parcela: qtdParcelas,
			});
		}
		setIsPgtoRecorrente(tipoPagamentoSelecionado == 'CRR');
		setIsCartaoCredito('CRC;CRR'.includes(tipoPagamentoSelecionado));
	}, [tipoPagamentoSelecionado]);

	const validarPagamento = () => {
		if (!cobranca?.tipoPagamento?.codigo) {
			notification({
				description: 'Selecione a forma de pagamento',
				type: 'warning',
				message: 'Aviso!',
			});
			return;
		}
		setCurrent(step.REVISAO);
	};

	useEffect(() => {
		if (cobranca?.cobrancaUnica) {
			if (
				tipoPagamentoSelecionado === 'BOL' ||
				tipoPagamentoSelecionado === 'PIX'
			) {
				setCobranca((prevState) => ({ ...prevState, parcela: 1 }));
			} else {
				return;
			}
		}
	}, [tipoPagamentoSelecionado]);
	// dados cartao
	const [form] = Form.useForm();
	const onChange = () => {
		if (isCartaoCredito) {
			if (!cobranca.cartao.numero) {
				notification({
					description: 'Preencha o número do cartão',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			if (!cobranca.cartao.bandeira) {
				form.setFieldsValue({ brand: ' ' });
				notification({
					description: 'Preencha a bandeira do cartão',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			if (!cobranca.cartao.nome) {
				notification({
					description: 'Preencha o nome do titular cartão',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			if (!cobranca.cartao.mes) {
				notification({
					description: 'Preencha o mês do cartão',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			if (!cobranca.cartao.ano) {
				notification({
					description: 'Preencha o ano do cartão',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			if (!cobranca.cartao.cvv) {
				notification({
					description: 'Preencha o código de segurança do cartão',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			} else {
				setCurrent(step.REVISAO);
			}
		} else {
			validarPagamento();
		}
	};

	function handleChange(value) {
		setCobranca({ ...cobranca, parcela: value });
	}
	function onChangeNumber(numero) {
		const { cartao } = cobranca;
		setCobranca({ ...cobranca, cartao: { ...cartao, numero } });
		const numericValue = numero.replace(/\D/g, '');
		let formatted = '';
		for (let i = 0; i < numericValue.length; i++) {
			if (i > 0 && i % 4 === 0) {
				formatted += ' ';
			}
			formatted += numericValue[i];
		}
		setFormattedValue(formatted);
	}
	function onChangeBrand(bandeira) {
		const { cartao } = cobranca;
		setCobranca({ ...cobranca, cartao: { ...cartao, bandeira } });
	}
	function onChangeName(nome) {
		const { cartao } = cobranca;
		setCobranca({ ...cobranca, cartao: { ...cartao, nome } });
	}
	function onChangeMonth(mes) {
		const { cartao } = cobranca;
		setCobranca({ ...cobranca, cartao: { ...cartao, mes } });
		const number = mes.replace(/\D/g, '');
		setFormatteMonth(number);
	}

	function onChangeYear(ano) {
		const { cartao } = cobranca;
		setCobranca({ ...cobranca, cartao: { ...cartao, ano } });
		const number = ano.replace(/\D/g, '');
		setFormatteYear(number);
	}

	function onChangeCvv(cvv) {
		const { cartao } = cobranca;
		setCobranca({ ...cobranca, cartao: { ...cartao, cvv } });
		const number = cvv.replace(/\D/g, '');
		setFormattedCvv(number);
	}

	// useEffect(() => {
	//     if (cobranca?.url != null && cobranca?.tipoPagamento?.codigo === "BOL") {
	//         // window.location.assign(cobranca?.url);
	//     }
	// }, [cobranca?.url])

	// const recaptchaRef = createRef<any>();

	const finaliza = async () => {
		setBtnVisible(true);
		setIsGlobalLoading(true);

		if (!cobranca.tipoPagamento?.codigo) {
			notification({
				description: 'Selecione a forma de pagamento',
				type: 'warning',
				message: 'Aviso!',
			});
			setIsGlobalLoading(false);
			setBtnVisible(false);
			return;
		}

		// const captcha = await recaptchaRef.current.getValue();
		// const environment = process.env.NODE_ENV;

		// if (environment === 'development' || captcha) {
		const response = await cobrancaService.findByIdOpen(
			cobranca?.id,
			token.access_token
		);
		const disabled =
			response?.data?.statusCobranca?.codigo ===
			TipoStatusCobranca.CODIGO_PAGO;
		if (!disabled) {
			cobrancaService
				.pagarme(cobranca, token.access_token)
				.then(({ data }) => {
					const { url, statusCobranca, acquirerReturnCode, qrCode } = data;
					setCobranca({
						...cobranca,
						url,
						statusCobranca,
						acquirerReturnCode,
						qrCode,
					});
					if (!'AGR,FLH'.includes(data.statusCobranca)) {
						setCurrent(step.CONCLUSAO);
					}
					if (data.statusCobranca === 'GRD') {
						const interval = setInterval(() => {
							cobrancaService
								.findStatus(data.id, token.access_token)
								.then(({ data }) => {
									if (data === 'PGO') {
										setCobranca({ ...cobranca, statusCobranca: data });
										clearInterval(interval);
									}
									if (
										cobranca?.url != null &&
										cobranca.tipoPagamento.codigo === 'BOL'
									) {
										// window.location.assign(cobranca.url);
										clearInterval(interval);
									}
								});
						}, 10000);
					}
					if (
						data.acquirerReturnCode &&
						data.statusCobranca !== 'PGO' &&
						isCartaoCredito
					) {
						switch (data.acquirerReturnCode) {
							case '0000':
								Modal.error({
									content: 'Pagamento não autorizado (Antifraude)',
									title: 'Aviso!',
								});
								break;

							case '1000':
								Modal.error({
									content:
										'Transação não autorizada, oriente o portador a contatar o banco/emissor do cartão',
									title: 'Aviso!',
								});
								break;

							case '2002':
								Modal.error({
									content: 'Transação com suspeita de fraude',
									title: 'Aviso!',
								});
								break;

							case '1001':
								Modal.error({
									content:
										'Pagamento não autorizado (Cartão vencido, a data de vencimento do cartão expirou)',
									title: 'Aviso!',
								});
								break;

							case '1011':
								Modal.error({
									content: 'Pagamento não autorizado (Cartão inválido)',
									title: 'Aviso!',
								});
								break;

							case '1016':
								Modal.error({
									content: 'Pagamento não autorizado (Saldo insuficiente)',
									title: 'Aviso!',
								});
								break;

							case '1022':
								Modal.error({
									content: 'Pagamento não autorizado (Violação de segurança)',
									title: 'Aviso!',
								});
								break;

							default:
								Modal.error({
									content: 'Pagamento não autorizado.',
									title: 'Aviso!',
								});
								break;
						}
					}
				})
				.finally(() => {
					setIsGlobalLoading(false);
					setBtnVisible(false);
				});
		} else {
			setIsGlobalLoading(false);
			setShowModal(true);
			setBtnVisible(false);
		}
		// } else {
		// 	setBtnVisible(false);
		// 	setIsGlobalLoading(false);
		// 	notification({
		// 		description: 'reCaptcha Inválido',
		// 		type: 'warning',
		// 		message: 'Aviso!',
		// 	});
		// }
	};

	const editarCartaoAssinatura = () => {
		setIsGlobalLoading(true);
		cobrancaService
			.editarCartaoAssinatura(cobranca.id, cobranca.cartao)
			.then(() => {
				notification({
					description: 'Sucesso na alteração do cartão!',
					type: 'success',
					message: 'Sucesso!',
				});
				setCurrent(step.CONCLUSAO);
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	};

	//useEffect(() => {
	//  if (tipoPagamentoSelecionado === 'BOL' || tipoPagamentoSelecionado === 'PIX') {
	//   setCobranca(prevState => ({ ...prevState, parcela: 1 }));
	// }
	// }, [tipoPagamentoSelecionado]);

	const body = () => {
		return cobranca?.statusCobranca === 'CAN' ||
			cobranca?.statusCobranca === 'RPC' ||
			cobranca?.statusCobranca === 'IST' ||
			cobranca?.statusCobranca === 'PGO' ? (
			<div>
				{cobranca.statusCobranca === 'CAN' && statusMessage.CAN.message}
				{cobranca.statusCobranca === 'RPC' && statusMessage.RPC.message}
				{cobranca.statusCobranca === 'IST' && statusMessage.IST.message}
				{cobranca.statusCobranca === 'PGO' && statusMessage.PGO.message}
			</div>
		) : (
			<div className={Style.body_section}>
				{current !== step.CONCLUSAO ? (
					<>
						<div className={Style.columnOne}>
							{/* step1 */}
							<div
								className={Style.columnOne_header}
								onClick={() => setCurrent(step.DADOS)}
							>
								<img
									src={vector}
									className={Style.arrow}
									style={
										current == 0
											? { transform: 'rotate(360deg)' }
											: { opacity: 0.5 }
									}
									alt="arrow"
								/>
								<h4
									className="mb-10 ml30"
									style={current == step.DADOS ? null : { opacity: 0.5 }}
								>
									parcelamento
								</h4>
							</div>
							<div
								className={
									current === step.DADOS ? `${Style.step_1}` : Style.closed
								}
							>
								<span className={Style.span_header}>
									Verifique aqui o extrato financeiro do seu parcelamento
								</span>

								<Step1
									cobranca={cobranca}
									cobrancaUnica={cobranca?.cobrancaUnica ? true : false}
								/>

								<button
									id="proximo"
									onClick={() => setCurrent(step.PAGAMENTO)}
									className={Style.btn_step1}
									style={{ margin: '0.3rem auto', width: '100%' }}
								>
									PRÓXIMO
								</button>
							</div>
							<div>
								{/* step2 */}
								<div
									className={Style.columnOne_header}
									onClick={() => setCurrent(step.PAGAMENTO)}
								>
									<img
										src={vector}
										className={Style.arrow}
										style={
											current == step.PAGAMENTO
												? { transform: 'rotate(360deg)' }
												: { opacity: 0.5 }
										}
										alt="arrow"
									/>
									<h4
										className="mb-10 ml30"
										style={current == step.PAGAMENTO ? null : { opacity: 0.5 }}
									>
										plano de pagamento
									</h4>
								</div>
								<div
									className={
										current === step.PAGAMENTO
											? `${Style.step_2}`
											: Style.closed
									}
								>
									<div className={Style.step_2_header}>
										<div>
											<span className={Style.span_header}>
												Escolha como deseja pagar
											</span>
										</div>
										<div>
											<img
												className={Style.creditCard}
												src={creditCard}
												alt="Cartões aceitos"
											/>
										</div>
									</div>
									<Radio.Group
										className={Style.radioGrup}
										onChange={(e) =>
											setTipoPagamentoSelecionado(e.target.value)
										}
										value={tipoPagamentoSelecionado}
									>
										{cobranca?.unidadeTipoPagamento?.map((tipo) =>
											tipo.tipoPagamento.codigo === 'CRR' &&
												(cobranca?.cobrancaUnica ||
													cobranca.quantidadeParcelas === 1 ||
													isAfter(cobranca.dataVencimento, Date.now())) ? null : (
												<Radio
													className={Style.box_payment_radio}
													value={tipo.tipoPagamento?.codigo}
													key={tipo.id}
												>
													{tipo.tipoPagamento.descricao}
												</Radio>
											)
										)}
									</Radio.Group>
									{cobranca?.tipoPagamento?.codigo === 'CRC' ||
										cobranca?.tipoPagamento?.codigo === 'CRR' ? (
										<div className={Style.box_credit_card}>
											<Form
												autoComplete="off"
												size="small"
												style={{ width: '100%' }}
											>
												<Input.Group compact className={Style.form_general}>
													<Form.Item
														name="q"
														rules={[
															{ required: true, message: 'Campo Obrigatório' },
														]}
														style={{ width: '80%' }}
													>
														<small>Insira o numero do cartão</small>
														<Input
															className={Style.input}
															placeholder="0000 0000 0000 0000"
															name="card"
															onChange={(e) => onChangeNumber(e.target.value)}
															value={formattedValue}
															maxLength={19}
														/>
													</Form.Item>
													<Form.Item
														name="brand"
														rules={[
															{ required: true, message: 'Campo Obrigatório' },
														]}
														style={{ width: '20%' }}
													>
														<small>Bandeira</small>
														<Select
															defaultValue=""
															size="middle"
															onChange={onChangeBrand}
														>
															<Option value="Mastercard">Mastercard</Option>
															<Option value="Visa">Visa</Option>
															<Option value="Diners">Diners Club</Option>
															<Option value="Amex">American Express</Option>
															<Option value="Elo">Elo</Option>
														</Select>
													</Form.Item>
												</Input.Group>
												<Input.Group className={Style.form_general}>
													<Form.Item
														name="name"
														rules={[
															{ required: true, message: 'Campo Obrigatório' },
														]}
														style={{ width: '100%' }}
													>
														<small>Nome do titular do cartão</small>
														<Input
															className={Style.input}
															placeholder="Campo Obrigatório"
															size="small"
															onChange={(e) => onChangeName(e.target.value)}
														/>
													</Form.Item>
												</Input.Group>
												<Input.Group compact className={Style.form_general}>
													<Form.Item
														name="month"
														rules={[
															{ required: true, message: 'Campo Obrigatório' },
														]}
														style={{ width: '33%' }}
													>
														<small>Mês</small>
														<Input
															maxLength={2}
															className={Style.input}
															placeholder="MM"
															size="small"
															onChange={(e) => onChangeMonth(e.target.value)}
															value={formatteMonth}
														/>
													</Form.Item>
													<Form.Item
														name="year"
														rules={[
															{ required: true, message: 'Campo Obrigatório' },
														]}
														style={{ width: '33%' }}
													>
														<small>Ano</small>
														<Input
															maxLength={2}
															className={Style.input}
															placeholder="AA"
															size="small"
															onChange={(e) => onChangeYear(e.target.value)}
															value={formatteYear}
														/>
													</Form.Item>
													<Form.Item
														name="cvv"
														rules={[
															{ required: true, message: 'Campo Obrigatório' },
														]}
														style={{ width: '33%' }}
													>
														<small>CVV</small>
														<Input
															maxLength={cobranca?.cartao?.bandeira === 'Amex' ? 4 : 3}
															className={Style.input}
															placeholder="CVV"
															size="small"
															onChange={(e) => onChangeCvv(e.target.value)}
															value={formattedCvv}
														/>
													</Form.Item>
												</Input.Group>

												<Form.Item
													rules={[
														{ required: true, message: 'Campo Obrigatório' },
													]}
													className={Style.form_general}
												>
													<small>Quantidade de parcelas</small>
													<Select
														defaultValue="1"
														placeholder="Selecione"
														style={{ width: '100%' }}
														onChange={handleChange}
													>
														{!cobranca?.cobrancaUnica ? (
															<Option value="1">
																1x de {formatMoney(cobranca?.valorCobranca)}
															</Option>
														) : (
															<>
																{Array.from(
																	Array(cobranca?.quantidadeParcelas),
																	(e, i) => i + 1
																).map((plPagamento, index) =>
																	cobranca?.quantidadeParcelas >= index ? (
																		<Select.Option
																			key={index + 1}
																			value={index + 1}
																		>
																			{index +
																				1 +
																				'x de ' +
																				formatMoney(
																					cobranca?.valorCobranca / (index + 1)
																				)}
																		</Select.Option>
																	) : null
																)}
															</>
														)}
													</Select>
												</Form.Item>
											</Form>
										</div>
									) : (
										<div className={Style.invisible_container}></div>
									)}
									<div className={Style.botoes}>
										{isPgtoRecorrente && idTipo === 'novo-cartao' ? (
											<button
												onClick={editarCartaoAssinatura}
												className={Style.btn_step2}
											>
												ENVIAR
											</button>
										) : (
											<>
												<button
													onClick={() => setCurrent(step.DADOS)}
													className={Style.btn_voltar}
												>
													VOLTAR
												</button>
												<button
													onClick={() => onChange()}
													className={Style.btn_proximo}
												>
													PRÓXIMO
												</button>
											</>
										)}
									</div>
								</div>
							</div>
							<div>
								{/* step3 */}
								<div
									className={Style.columnOne_header}
									onClick={() => {
										if (current === step.PAGAMENTO) {
											onChange();
										}
										return;
									}}
								>
									<img
										src={vector}
										className={Style.arrow}
										alt="arrow"
										style={
											current == 2
												? { transform: 'rotate(360deg)' }
												: { opacity: 0.5 }
										}
									/>
									<h4
										className="mb-10 ml30"
										style={current == 2 ? null : { opacity: 0.5 }}
									>
										revisão
									</h4>
								</div>
								<div
									className={
										current === step.REVISAO ? `${Style.step_3}` : Style.closed
									}
								>
									<div>
										<span className={Style.span_header}>
											Faça a revisão do seu pagamento
										</span>
									</div>

									<Step3
										btnVisible={btnVisible}
										cobranca={cobranca}
										finaliza={finaliza}
										// recaptchaRef={recaptchaRef}
										originalVencimento={originalVencimento}
										setCurrent={setCurrent}
										step={step}
										parcela={parcela}
									/>
								</div>
							</div>
						</div>
						<div className={Style.columnTwo}>
							<div className={Style.columnTwo_header}>
								<h4 className="mb-5">dados do aluno</h4>
							</div>
							<Skeleton
								loading={loading}
								title={true}
								active={true}
								paragraph={{
									rows: 4,
									width: ['55%', '40%', '55%', '40%'],
								}}
							>
								<div>
									<h5 className="mt-10">NOME</h5>
									<p className="ti5 mb10">{cobranca?.aluno?.nome}</p>
									<h5>RESPONSÁVEL</h5>
									<p className="ti5 mb10">{cobranca?.responsavel?.nome}</p>
									<h5>CONTATOS</h5>
									<p className="ti5">{cobranca?.responsavel?.email}</p>
									<p className="ti5 mb5">{cel ? formatPhone(cel) : tel}</p>
								</div>
							</Skeleton>
							<div className={Style.columnTwo_header}>
								<h4 className="mb-5">dados da matrícula</h4>
							</div>
							<CursosContratados cobranca={cobranca} loading={loading} />
						</div>
					</>
				) : null}
			</div>
		);
	};
	return (
		<div className={Style.body}>
			{header()}
			{showModal && <ModalCustom />}
			{current === step.CONCLUSAO && <Pagamento cobranca={cobranca} />}
			{body()}
		</div>
	);
}

export default CheckoutNew;
