import { SendOutlined, CaretDownOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import MenuSidebarAndContainer from '../../components/MenuSidebarAndContainer/menu-sidebar-and-container.component'
import { useGlobalContext } from '../../context/GlobalContext';

import './style.css'
import { Button } from 'antd';
import { useState } from 'react';
import { useFalaComandanteService } from '../../services/fala-comandante.service';
import { useNotificationService } from '../../services/notification.service';

export function falaComandante() {

    const { usuario, unidade, setIsGlobalLoading } = useGlobalContext();
    const [mensagemEmail, setMensagemEmail] = useState(null);
    const [opcaoRegistro, setOpcaoRegistro] = useState("Elogio");
    const notification = useNotificationService();


    const { sendFeedback } = useFalaComandanteService();

    const handleSubmit = async (e) => {    
        const dto = {
            nomeComandante: usuario?.usuario.split(' ')[0],
            unidadeComandante: unidade.descricao,
            opcaoRegistro,
            mensagemEmail
        }

        try {
            setIsGlobalLoading(true);
            await sendFeedback(dto);
            setIsGlobalLoading(false);
            setMensagemEmail("");
        } catch (error) {
            console.error("Erro ao enviar feedback:", error);
        }
    }

    const validationFields = (e) => {
        e.preventDefault();

        if(!mensagemEmail){
            notification({
                message: "Aviso!",
                type: 'warning',
                description: 'Favor preencha o campo de comentário'
             })
            return
        }

        handleSubmit(e);
    }

    return (
        <MenuSidebarAndContainer itemMenuSelected={35}>
        <div className='content-all'>
            <section className='content-box'>
                <div className='content'>
                    <div className='introduction'>
                        <h1>fala, comandante!</h1>
                        <p>
                            Esse é o seu espaço para compartilhar elogios, críticas ou sugestões em relação ao <b>nosso atendimento.</b><br />
                            Aqui, você poderá trazer suas contribuições que serão fundamentais para o crescimento contínuo da Happy.
                        </p>
                        <h2>Fique à vontade para falar com a gente!</h2>
                    </div>
                    <div className='form-comander'>
                        <form onSubmit={validationFields}>
                            <div className='select-box'>
                                <label>O que você gostaria de registrar?</label>
                                <div className='select-wrapper'>
                                    <select
                                        id="register-type"
                                        value={opcaoRegistro}
                                        onChange={e => setOpcaoRegistro(e.target.value)}
                                    >
                                        <option value="Elogio">Elogio</option>
                                        <option value="Crítica">Crítica</option>
                                        <option value="Sugestão de Melhoria">Sugestão de Melhoria</option>
                                    </select>
                                    <CaretDownOutlined className='custom-arrow' rev={undefined} />
                                </div>
                            </div>
                            <div className='coment-box'>
                                <label>Deixe seu comentário:</label>
                                <TextArea
                                    className="text-area"
                                    value={mensagemEmail}
                                    onChange={(e) => setMensagemEmail(e.target.value)}
                                />
                            </div>
                            <div className='button'>
                                <Button
                                    style={{ width: '200px', height: '40px', borderRadius: '20px', backgroundColor: '#3FC91B' }}
                                    htmlType='submit'
                                >
                                    <SendOutlined alt='Enviar' rev={undefined} />Enviar
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        </MenuSidebarAndContainer>
    )
}