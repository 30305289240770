import * as Unicons from '@iconscout/react-unicons';
import { Checkbox, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import NewMenu from '../../../components/NewMenu';
import { useUnidade } from '../hooks/use-find-unidade.hook';
import { useUnidadeForm } from '../hooks/use-form-vinculo-externo.hook';
import '../style.css';
import { useUnidadeService } from '../../../services/unidade.service';
import { useEffect } from 'react';

const VinculoExterno: React.FunctionComponent = () => {
    const { unidade, setUnidade } = useUnidade();
    const { form, unidadeUpdate,updateCertified, getVencimento, dataVencimento} = useUnidadeForm(unidade);
    const unidadeService  = useUnidadeService();
    

    const setCheck = () => {
        setUnidade({ ...unidade, capital: !unidade?.capital });
    };

    useEffect (() => {
        getVencimento(); 
    },[unidade])
 
    return (
        <div className="container">
            <NewMenu selecionado={10} />
            <div className="franquia" style={{ height: '100vh', overflowY: 'scroll', left: 'calc(100vw - 95%)', paddingTop: 50 }}>
                <Form
                    form={form}
                    name="control-hooks"
                    onFinish={unidadeUpdate}
                >
                    <div className="franquia-row">
                        <h5>Registrar IDs de serviços terceiros</h5>
                        <Form.Item name="id" />
                        <div className="box-cidade" style={{ gap: 5 }}>
                            <div className="select-franquia">
                                <span>Pagar.me</span>
                                <Form.Item
                                    name='identificadorPagarMe'
                                    className='wp-100'
                                    initialValue={unidade?.identificadorPagarMe?.trim()}
                                    rules={[{ required: false, message: 'Informe o Pagar.me' }]}
                                >
                                    <Input id="identificadorPagarMe" className="input" />
                                </Form.Item>
                            </div>
                            <div className="select-franquia">
                                <span>Pagar.me V4</span>
                                <Form.Item
                                    name='identificadorPagarMeV4'
                                    className='wp-100'
                                    initialValue={unidade?.identificadorPagarMeV4?.trim()}
                                    rules={[{ required: false, message: 'Informe o Pagar.me V4' }]}
                                >
                                    <Input id="identificadorPagarMeV4" className="input" />
                                </Form.Item>
                            </div>
                            <div className="select-franquia" style={{ width: '10%' }}>
                                <span>Capital</span>
                                <Checkbox checked={unidade?.capital} onChange={setCheck} />
                            </div>
                        </div>

                        <div className="box-cidade" style={{ gap: 5 }}>
                            <div className="select-franquia">
                                <span>Facebook</span>
                                <Form.Item
                                    name='facebookId'
                                    className='wp-100'
                                    rules={[{ required: false, message: 'Informe o Facebook' }]}
                                    initialValue={unidade?.facebookId?.trim()}
                                >
                                    <Input id="facebookId" className="input" />
                                </Form.Item>
                            </div>
                            <div className="select-franquia" style={{ width: '58%' }}>
                                <span>Máquina física</span>
                                <Form.Item
                                    name='identificadorDevice'
                                    className='wp-100'
                                    initialValue={unidade?.identificadorDevice?.trim()}
                                    rules={[{ required: false, message: 'Informe o device' }]}
                                >
                                    <Input id="identificadorDevice" className="input" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="box-cidade" style={{ gap: 5 }}>
                            <div className="select-franquia">
                                <span>@ Instagram</span>
                                <Form.Item
                                    name='dsInstagram'
                                    className='wp-100'
                                    initialValue={unidade?.dsInstagram?.trim()}
                                    rules={[{ required: false, message: 'Informe o Instagram' }]}
                                >
                                    <Input id="dsInstagram" className="input" />
                                </Form.Item>
                            </div>
                            <div className="select-franquia" style={{ width: '58%' }}>
                                <span>LP HUB</span>
                                <Form.Item
                                    name='dsLpHub'
                                    className='wp-100'
                                    rules={[{ required: false, message: 'Informe o LP HUB' }]}
                                    initialValue={unidade?.dsLpHub?.trim()}
                                >
                                    <Input id="dsLpHub" className="input" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="box-cidade" style={{ gap: 5 }}>
                            <div className="select-franquia">
                                <span className='eNotas'>eNotas</span>
                                <Form.Item
                                    name='identificadorEnotas'
                                    className='wp-100 identificadorEnotas'
                                    initialValue={unidade?.identificadorEnotas?.trim()}
                                    rules={[{ required: false, message: 'Informe o eNotas' }]}
                                >
                                    <Input id="identificadorEnotas" className="input" />
                                </Form.Item>
                            </div>
                            {unidade?.identificadorEnotas &&(
                                <div className="select-franquia">
                                <Input defaultValue={dataVencimento} value={dataVencimento} style={{textAlign:"center", color:"#000"}} disabled/>
                                <button className="button-primary" onClick={updateCertified}><span>Atualizar Certificado</span></button>
                            </div>
                            )}
                        </div>
                        <div className="select-franquia">
                            <span>Grupo de E-mail do hub</span>
                            <Form.Item
                                name='email'
                                className='wp-100'
                                rules={[{ required: false, message: 'Informe o Grupo de E-mail do hub' }]}
                                initialValue={unidade?.email?.trim()}
                            >
                                <Input id="email" className="input" />
                            </Form.Item>
                        </div>
                        <div className="select-franquia">
                            <span>Telefone do hub</span>
                            <Form.Item
                                name='telefone'
                                className='wp-100'
                                rules={[{ required: false, message: 'Informe o Telefone do hub' }]}
                                initialValue={unidade?.telefone?.trim()}
                            >
                                <Input id="telefone" className="input" maxLength={11} />
                            </Form.Item>
                        </div>

                        <div className="botoes">
                            <Link to="/franquias">
                                <button className="button-second" id="voltar" style={{ width: 345 }}>Voltar</button>
                            </Link>
                            <button type="submit" className="button-primary" id="proximo" style={{ width: 345 }}><span>Salvar</span></button>
                        </div>
                    </div>
                </Form>
            </div>
            <Link to="/franquias">
                <div className="fechar-terceiros"><Unicons.UilTimes size="20" color="#353F49" /></div>
            </Link>
        </div>
    );
};

export default VinculoExterno;
