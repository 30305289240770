import { addDays, differenceInDays, format, isAfter, isBefore, isEqual, isValid, parseISO } from 'date-fns';
import moment from 'moment';

export const formatMoney = (value: number) => {
	if (isNaN(value)) return 'R$ 0,00';
	return value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

export const formatPercent = (value: number) => {
	return `${(value * 100)?.toFixed(1)}%`;
};

export const formattedDate = (date: string | Date) => {
	if (!date) return '';

	const parsedDate = typeof date === 'string' ? parseISO(date) : date;
	if (!isValid(parsedDate)) return '';

	return format(parsedDate, 'dd/MM/yyyy');
};

export const toMoment = (date) => date && moment(date);

export const isDateBetween = (dateIni, dateEnd) => {
	const today = new Date();
	dateIni = parseISO(dateIni).setHours(0, 0, 0, 0);
	dateEnd = parseISO(dateEnd).setHours(23, 59, 59, 0);
	return isEqual(today, dateEnd) || isEqual(today, dateIni) || (isAfter(today, dateIni) && isBefore(today, dateEnd));
};

export const isAfterDate = (date) => {
	const today = new Date();
	return isAfter(today, parseISO(date));
};

export const isBeforeDate = (date) => {
	const today = new Date();
	return isBefore(today, parseISO(date));
};

export const isBeforeOrToday = (date) => {
	const today = new Date();
	const parsedDate = parseISO(date);
	return isBefore(parsedDate, today) || isEqual(parsedDate, today);
};

export const daysBetween = (date) => (isAfterDate(date) ? differenceInDays(new Date(), parseISO(date)) : 0);

export const daysBetweenBefore = (date) => (isBeforeDate(date) ? differenceInDays(parseISO(date), new Date()) : 0);

export const formatMomentLocalDateTime = (date) => date && date.format('YYYY-MM-DDT00:00:00.000');

export const formatMomentLocalDate = (date) => date && date.format('YYYY-MM-DD');

export const nowDateTime = () => {
	return format(new Date(), "yyyy-MM-dd'T'00:00:00.000");
};

export const formatLocaleDateString = (date) => {
	return date ? new Date(date).toLocaleDateString("pt-BR") : "-";
};

export const removeSecondsFromHour = (hour: string) => hour.split(':').slice(0, -1).join(':');

export const nowDate = () => {
	return format(new Date(), 'yyyy-MM-dd');
};

export const nowDateAddDays = (qtd) => {
	return addDays(new Date(), qtd);
};

export const getMonthName = (monthNumber: number): string => {
	return moment.months(monthNumber - 1);
};

export const currencyFormatter = (value) => {
	if (!value) return 'R$ ';

	const number = parseFloat(value);

	return number.toLocaleString('pt-br', {
		style: 'currency',
		currency: 'BRL',
	});
};

export const formatValue = (valor: number): string => {
	const value = parseInt(valor?.toString());
	const suffixes = ['', 'K', 'M', 'B', 'T'];
	const suffixNum = Math.floor(('' + value).length / 3);
	const divisao = 1000;
	let shortValue = parseFloat((suffixNum !== 0 ? value / divisao : value).toPrecision(2));
	if (shortValue % 1 !== 0) {
		shortValue = Number(shortValue.toFixed(1));
	}
	return shortValue + suffixes[suffixNum];
};

export const currencyParser = (val: any) => {
	try {
		// for when the input gets clears
		if (typeof val === 'string' && !val.length) {
			val = '0.0';
		}

		// detecting and parsing between comma and dot
		const group = new Intl.NumberFormat('pt-br').format(1111).replace(/1/g, '');
		const decimal = new Intl.NumberFormat('pt-br').format(1.1).replace(/1/g, '');
		let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
		reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
		//  => 1232.21 €

		// removing everything except the digits and dot
		reversedVal = reversedVal.replace(/[^0-9.]/g, '');
		//  => 1232.21

		// appending digits properly
		const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
		const needsDigitsAppended = digitsAfterDecimalCount > 2;

		if (needsDigitsAppended) {
			reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
		}

		return Number.isNaN(reversedVal) ? 0 : reversedVal;
	} catch (error) {
		console.error(error);
	}
};

export const removeAcentos = (str: string) => str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const formatCpf = (value: any) => {
	return value
		?.replace(/\D/g, '')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1');
};

export const formatRG = (value: string): string => {
	const onlyDigits = value?.replace(/\D/g, ''); // Remove tudo que não é número
	const maxLength = 9; // Define o número máximo de dígitos para o RG

	const formattedRG = onlyDigits?.slice(0, maxLength).replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4'); // Aplica a formatação desejada (por exemplo: 99.999.999-9)

	return formattedRG;
};

export const formatPhone = (value: any) => {
	return value
		?.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '($1) $2')
		.replace(/(\d{5})(\d)/, '$1-$2')
		.replace(/(-\d{4})\d+?$/, '$1');
};

export const removeAcentuation = (text) => {
	return text?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const formatHora = (date: Date) => {
	const momentDate = moment(date);
	const horario = momentDate.format('HH:mm');
	return horario.toString();
};

export const formatTime = (minutes: any): string => {
	// formatar minutos para horas 00h00
	const hours = Math.floor(minutes / 60);
	const remainingMinutes = minutes % 60;
	return `${hours.toString().padStart(2, '0')}h${remainingMinutes.toString().padStart(2, '0')}`;
};

export const formatTimeFromString = (timeString: string): string => {
	// formatar data a partir de string 00:00:00 > 00h00
	const [hours, minutes] = timeString.split(':');
	if (minutes === '00') {
		return `${hours.padStart(2, '0')}h`;
	}
	return `${hours.padStart(2, '0')}h${minutes.padStart(2, '0')}`;
};

export const formatarData = (date) => {
	date.setDate(date.getDate() + 3); // Adiciona 3 dias à data fornecida
	const dia = date.getDate().toString().padStart(2, '0'); // Extrai o dia e formata com dois dígitos
	const mes = (date.getMonth() + 1).toString().padStart(2, '0'); // Extrai o mês e formata com dois dígitos
	const ano = date.getFullYear(); // Extrai o ano
	return `${dia}/${mes}/${ano}`; // Retorna a data no formato dia/mês/ano
};

export const formatExelencia = (numero) => {
	return (numero || 0.0).toFixed(2).replace('.', ',');
};

export const formatDataRelatorio = (date) => {
	return date ? moment.utc(date).format('DD/MM/YYYY') : '00/00/0000';
};

//   const distance = formatDistance(
//     firstDate,
//     secondDate
//   );

//   // 24 horas

//   const relative = formatRelative(
//     firstDate,
//     secondDate
//   );

// Ontem às 16h
