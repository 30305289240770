import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Operators } from '../../enum/operators.enum';
import { Predicate } from '../../models/predicate.model';

interface Props {
	predicate: Predicate;
	findHabilidade: () => void;
}
const FiltrosHabilidades = memo(({ predicate, findHabilidade }: Props) => {
	const [descricao, setDescricao] = useState<string>();
	const [status, setStatus] = useState<number>();

	useEffect(() => {
		const typingTimeout = setTimeout(() => {
			predicate.removeAllOption();
			if (descricao) {
				predicate.addOption('descricao', descricao, Operators.CONTAINS);
			}
			if (status) {
				predicate.addOption('ativo', status == 1 ? true : false);
			}
			findHabilidade();
		}, 800);
		return () => clearTimeout(typingTimeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [descricao, status]);

	return (
		<>
			<div className="filtros">
				<div className="input-text" style={{ width: '90%' }}>
					<Input
						id="buscar"
						value={descricao}
						placeholder="Buscar habilidade"
						prefix={
							<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />
						}
						onChange={(e) => setDescricao(e.target.value)}
					/>
				</div>
				<div className="filtro-estado input-dashboard" style={{ width: '15%' }}>
					<Select
						placeholder="Status"
						className="ant-dropdown-link"
						style={{ width: '100%' }}
						onChange={(e: number) => setStatus(e)}
					>
						<Select.Option value={0}>Todos</Select.Option>
						<Select.Option key={1} value={1}>
							Ativo
						</Select.Option>
						<Select.Option key={2} value={2}>
							Inativo
						</Select.Option>
					</Select>
				</div>
			</div>
		</>
	);
});

FiltrosHabilidades.displayName = 'FiltrosHabilidades';

export default FiltrosHabilidades;
