import { CloseOutlined } from '@mui/icons-material';
import { Button, Input, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import '../../../src/global.css';
import Header from '../../components/Header';
import './style.css';

import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { IQuizDTO } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';
import { useQuizService } from '../../services/quiz.service';

const QuizCreate: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const [quiz, setQuiz] = useState<IQuizDTO>();
	const { idQuiz } = useParams<any>();
	const quizService = useQuizService();

	const findQuizz = () => {
		setIsGlobalLoading(true);
		quizService
			.findById({ id: idQuiz })
			.then((response) => {
				setQuiz(response.data);
				console.log(response.data);
				console.log(`quiz:${quiz}`);
			})
			.catch(() => {
				notification({
					description: 'Erro ao buscar os dados do quiz.',
					type: 'error',
					message: 'Erro!',
				});
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		if (idQuiz) {
			findQuizz();
		}
		return;
	}, [idQuiz]);

	const submit = () => {
		setIsGlobalLoading(true);
		if (!idQuiz) {
			quizService
				.create(quiz)
				.then(() => {
					notification({
						description: `Quiz cadastrado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/quiz');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		} else {
			quizService
				.patch(quiz)
				.then(() => {
					notification({
						description: `Quiz editado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/quiz');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};
	const submitValidationFields = () => {
		if (!quiz?.enunciado) {
			notification({
				description: `Por favor, preencha o campo do enunciado`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!quiz?.alternativa1) {
			notification({
				description: `Por favor, preencha o campo questão 01.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!quiz?.alternativa2) {
			notification({
				description: `or favor, preencha o campo questão 02.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!quiz?.alternativa3) {
			notification({
				description: `por favor, preencha o campo questão 03.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!quiz?.alternativa4) {
			notification({
				description: `por favor, preencha o campo questão 04.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		submit();
	};
	return (
		<div className="container-fondo">
			<Header />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row">
					<div className="card-form">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira informações para {idQuiz ? 'edição' : 'criação'} de quiz
							</h3>
							<Button
								onClick={() => setIsOpen(true)}
								id="cancelar"
								className="button-close"
								icon={<CloseOutlined />}
							/>
						</div>
						<div className="input-box">
							<label htmlFor="enunciado">Enunciado</label>
							<Input
								defaultValue={quiz?.enunciado}
								value={quiz?.enunciado}
								id="enunciado"
								maxLength={100}
								placeholder="Qual é a capital da França?"
								onChange={(e) =>
									setQuiz({ ...quiz, enunciado: e.target.value })
								}
							/>
						</div>
						<div className="input-box">
							<label htmlFor="question01">Questão 1</label>
							<Input
								id="question01"
								defaultValue={quiz?.alternativa1}
								value={quiz?.alternativa1}
								maxLength={100}
								placeholder="Ex.: Paris"
								onChange={(e) =>
									setQuiz({ ...quiz, alternativa1: e.target.value })
								}
							/>
						</div>
						<div className="input-box">
							<label htmlFor="question02">Questão 2</label>
							<Input
								defaultValue={quiz?.alternativa2}
								value={quiz?.alternativa2}
								id="question02"
								maxLength={100}
								placeholder="Ex.: Londres"
								onChange={(e) =>
									setQuiz({ ...quiz, alternativa2: e.target.value })
								}
							/>
						</div>
						<div className="input-box">
							<label htmlFor="question03">Questão 3</label>
							<Input
								defaultValue={quiz?.alternativa3}
								value={quiz?.alternativa3}
								id="question03"
								maxLength={100}
								placeholder="Ex.: Berlim"
								onChange={(e) =>
									setQuiz({ ...quiz, alternativa3: e.target.value })
								}
							/>
						</div>
						<div className="input-box">
							<label htmlFor="question04">Questão 4</label>
							<Input
								defaultValue={quiz?.alternativa4}
								value={quiz?.alternativa4}
								id="question03"
								maxLength={100}
								placeholder="Ex.: Roma"
								onChange={(e) =>
									setQuiz({ ...quiz, alternativa4: e.target.value })
								}
							/>
						</div>
						<div className="container-inputs">
						<h4 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira os dados da idade mínima e máxima para o quiz
							</h4>
						<div className="input-box" style={{ width: '50%' }}>
							<label htmlFor="idadeMin">Idade Mínima</label>
							<InputNumber
								style={{ width: '100%' }}
								defaultValue={quiz?.idadeMinima}
								value={quiz?.idadeMinima}
								id="idadeMin"
								placeholder="Ex.: 5"
								min={1}
								max={99}
								onChange={(e) => setQuiz({ ...quiz, idadeMinima: e })}
							/>
						</div>
						<div className="input-box" style={{ width: '50%' }}>
							<label htmlFor="idadeMax">Idade Máxima</label>
							<InputNumber
								style={{ width: '100%' }}
								defaultValue={quiz?.idadeMaxima}
								value={quiz?.idadeMaxima}
								id="idadeMin"
								placeholder="Ex.: 18"
								min={1}
								max={99}
								onChange={(e) => setQuiz({ ...quiz, idadeMaxima: e })}
							/>
						</div>
						</div>
						<div className="botoes" style={{ marginTop: 10 }}>
							<Link to={`/quiz`}>
								<Button
									className="button-second"
									id="voltar"
									style={{ width: 345, marginRight: 10 }}
								>
									Voltar
								</Button>
							</Link>
							<Button
								style={{ width: 345 }}
								type="primary"
								id="proximo"
								className="button-primary"
								onClick={submitValidationFields}
							>
								Salvar
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/quiz`}
			/>
		</div>
	);
};

export default QuizCreate;
