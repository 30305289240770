import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { IQuizDTO } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';
import { useQuizService } from '../../services/quiz.service';

interface TItemLista {
	quiz: IQuizDTO;
}

const ItemListQuiz = memo(({ quiz }: TItemLista) => {
	const [ativo, setAtivo] = useState<boolean>();

	const notification = useNotificationService();
	const history = useHistory();
	const { setIsGlobalLoading } = useGlobalContext();
	const quizService = useQuizService();

	const goTo = () => {
		history.push(`/quiz/${quiz?.id}/create`);
	};

	useEffect(() => {
		setAtivo(quiz.ativo);
	}, [quiz.ativo]);

	const showDeleteConfirm = () => {
		Modal.confirm({
			title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} o quiz?`,
			icon: <ExclamationCircleTwoTone rev={undefined} />,
			okText: 'Sim',
			okType: `${ativo ? 'danger' : 'primary'}`,
			cancelText: 'Não',
			onOk: () =>
				quizService
					.patch({ id: quiz.id, ativo: !ativo })
					.then(() => {
						setAtivo(!ativo);
						notification({
							description: 'Quiz alterado com sucesso!',
							type: 'success',
							message: 'Sucesso',
						});
					})
					.finally(() => setIsGlobalLoading(false)),
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onCancel: () => {},
		});
	};

	const menu = (
		<Menu>
			<Menu.Item onClick={showDeleteConfirm}>
				<a rel="noopener noreferrer">Suspender/Ativar</a>
			</Menu.Item>
		</Menu>
	);

	return (
		<div
			className="registro-user"
			style={{ padding: 20 }}
			id="registro-financeiro"
		>
			<div className="user" id="cursos" style={{ width: '27%' }}>
				<div className="nome">
					<p>{quiz.enunciado}</p>
					{ativo ? (
						<div>
							<div className="estado-user">
								<div className="dot-verde"></div>
								<p className="ativo">Ativo</p>
							</div>
						</div>
					) : (
						<div>
							<div className="estado-user">
								<div className="dot-vermelho"></div>
								<p className="inativo">Inativo</p>
							</div>
						</div>
					)}
				</div>
			</div>
			<div
				className="funcao-user"
				id="conteudo-id"
				style={{ width: '23%', textAlign: 'center', fontSize: '0.9rem' }}
			>
				<p>{quiz.alternativa1}</p>
			</div>
			<div
				className="funcao-user"
				id="cursos-modalidade"
				style={{ width: '29%', fontSize: '0.9rem' }}
			>
				<p>{quiz.alternativa2}</p>
			</div>
			<div
				className="funcao-user"
				id="cursos-id"
				style={{ width: '21%', fontSize: '0.9rem', textAlign: 'center' }}
			>
				<p>{quiz.alternativa3}</p>
			</div>
			<div
				className="funcao-user"
				id="cursos-id"
				style={{ width: '21%', fontSize: '0.9rem', textAlign: 'center' }}
			>
				<p>{quiz.alternativa4}</p>
			</div>
			<div
				className="funcao-user"
				id="cursos-id"
				style={{ width: '16%', fontSize: '0.9rem', textAlign: 'center' }}
			>
				<p>{quiz.idadeMinima}</p>
			</div>
			<div
				className="funcao-user"
				id="cursos-modalidade"
				style={{ width: '16%' }}
			>
				<p>{quiz.idadeMaxima}</p>
			</div>
			<div
				className="funcao-user"
				id="cursos-modalidade"
				style={{ width: '5%' }}
			>
				<Dropdown overlay={menu} placement="bottomRight" arrow>
					<Unicons.UilEllipsisH
						className="button-dropdown"
						size="18"
						color="#6A7C96"
					/>
				</Dropdown>
			</div>
			<div className="registro-opcao" style={{ width: '5%' }} onClick={goTo}>
				<i className="row-user__action-cell">
					<Unicons.UilAngleRight
						size="32"
						className="action-cell__action-icon"
					/>
				</i>
			</div>
		</div>
	);
});

ItemListQuiz.displayName = 'ItemListQuiz';

export default ItemListQuiz;
function setIsGlobalLoading(arg0: boolean): void {
	throw new Error('Function not implemented.');
}
