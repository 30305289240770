import { ColumnFilterItem } from 'antd/lib/table/interface';
import { useState } from 'react';
import { INegocioCleanDTO, IStatusCobrancaCleanDTO, IStatusMatriculaCleanDTO, ITipoCurriculoCleanDTO, ITipoPagamentoCleanDTO } from '../../../../../models/happy-code-api.model';
import { useNegocioService } from '../../../../../services/negocio.service';
import { useStatusCobrancaService } from '../../../../../services/status-cobranca.service';
import { useStatusMatriculaService } from '../../../../../services/status-matricula.service';
import { useTipoCurriculoService } from '../../../../../services/tipo-curriculo.service';
import { useTipoPagamentoService } from '../../../../../services/tipo-pagamento.service';

const useRelatorioHook = () => {
    const negocioService = useNegocioService();
    const tipoCurriculoService = useTipoCurriculoService();
    const statusCobrancaService = useStatusCobrancaService();
    const statusMatriculaService = useStatusMatriculaService();
    const tipoPagamentoService = useTipoPagamentoService();

    const [negocios, setNegocios] = useState<INegocioCleanDTO[]>([]);
    const [tipoCurriculo, setTipoCurriculo] = useState<ITipoCurriculoCleanDTO[]>([]);
    const [statusCobrancas, setStatusCobrancas] = useState<IStatusCobrancaCleanDTO[]>([]);
    const [statusMatriculas, setStatusMatriculas] = useState<IStatusMatriculaCleanDTO[]>([]);
    const [tipoPagamentos, setTipoPagamentos] = useState<ITipoPagamentoCleanDTO[]>([]);
    const arrayStatusCobranca = ["Okay", "Atrasada", "Pendente"];
    const arrayStatus = ["Ativo", "Inativo"];

    const selectStatusNfs: ColumnFilterItem[] = [
        { text: 'Sim', value: 'Sim' },
        { text: 'Não', value: 'Não' },
    ];

    const selectStatus: ColumnFilterItem[] = arrayStatus.map((status) => ({
        text: status,
        value: status,
    }));

    const selectStatusParcelaCobranca: ColumnFilterItem[] = arrayStatusCobranca.map((statusCobranca) => ({
        text: statusCobranca,
        value: statusCobranca,
    }));

    const findTipoPagamento = async () => {
        return tipoPagamentoService.findCleanList().then(({ data }) => {
            setTipoPagamentos(data);
        });
    };

    const selectTipoPagamento: ColumnFilterItem[] = tipoPagamentos.map((tipoPagamento) => ({
        text: tipoPagamento.descricao,
        value: tipoPagamento.descricao,
    }));

    const findStatusMatricula = async () => {
        return statusMatriculaService.findCleanList().then(({ data }) => {
            setStatusMatriculas(data);
        });
    };

    const selectStatusMatricula: ColumnFilterItem[] = statusMatriculas.map((statusMatricula) => ({
        text: statusMatricula.descricao,
        value: statusMatricula.descricao,
    }));


    const findStatusCobranca = async () => {
        return statusCobrancaService.findCleanList().then(({ data }) => {
            setStatusCobrancas(data);
        });
    };

    const selectStatusCobranca: ColumnFilterItem[] = statusCobrancas.map((statusCobranca) => ({
        text: statusCobranca.descricao,
        value: statusCobranca.descricao,
    }));

    const findTipoCurriculo = () => {
        return tipoCurriculoService.findCleanList().then(({ data }) => {
            setTipoCurriculo(data);
        });
    };

    const selectTipoCurriculo: ColumnFilterItem[] = tipoCurriculo.map((tipoCurriculo) => ({
        text: tipoCurriculo.descricao,
        value: tipoCurriculo.descricao,
    }));

    const findNegocio = async () => {
        return negocioService.findCleanList().then(({ data }) => {
            setNegocios(data);
        });
    };

    const selectNegocios: ColumnFilterItem[] = negocios.map((negocio) => ({
        text: negocio.descricao,
        value: negocio.descricao,
    }));

    return {
        selectStatusNfs,
        selectStatusParcelaCobranca,
        selectTipoPagamento,
        selectStatus,
        selectNegocios,
        selectStatusCobranca,
        selectStatusMatricula,
        selectTipoCurriculo,
        findStatusMatricula,
        findTipoCurriculo,
        findTipoPagamento,
        findNegocio,
        findStatusCobranca,
    };
};

export default useRelatorioHook;
