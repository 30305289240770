/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react';
import { IUnidadeFullDTO } from '../models/happy-code-api.model';
import { getObject, removeKey, setObject } from '../util/store';

//Tipando as Props do contexto
interface PropsUnidadeContext {
	unidade: IUnidadeFullDTO;
	revisao: boolean;
	upload: any;
	setUnidade: React.Dispatch<React.SetStateAction<IUnidadeFullDTO>>;
	removeUnidade: React.DispatchWithoutAction;
	setRevisao: React.Dispatch<React.SetStateAction<boolean>>;
	setUpload: React.Dispatch<React.SetStateAction<any>>;
}

//Valor default do contexto
const DEFAULT_VALUE: PropsUnidadeContext = {
	unidade: {
		id: null,
		codigo: '',
		descricao: '',
		cnpj: '',
		inscricaoEstadual: '',
		// codigoCluster: "",
		dataAbertura: undefined,
		dataContrato: undefined,
		dataRenovacaoContrato: undefined,
		dataTerminoContrato: undefined,
		dataInicioFerias: undefined,
		dataTerminoFerias: undefined,
		pendencia: false,
		ativo: true,
		identificadorPagarMe: null,
		identificadorEnotas: null,
		identificadorPagarMeV4: null,
		identificadorPagarMePos: null,
		identificadorPagarMeEscola: null,
		identificadorDevice: null,
		facebookId: '',
		banco: '',
		agencia: '',
		digitoAgencia: '',
		conta: '',
		digitoConta: '',
		email: '',
		telefone: '',
		telefoneComandante: '',
		telefoneGuerreiro: '',
		razaoSocial: '',
		capital: null,
		// unidadeAtuacao: "",
		// unidadeCaracteristica: "",
		diaFuncionamento: '',
		dsInstagram: '',
		dsLpHub: '',
		horaAbertura: null,
		horaFechamento: null,
		horaAulaInicio: null,
		horaAlmocoInicio: null,
		horaAlmocoFim: null,
		intervalo: null,
		flIntervalo: null,
		dtVencimento: null,
		modeloUnidade: {
			id: null,
			codigo: '',
			descricao: '',
			ativo: true,
		},
		atuacao: {
			id: null,
			codigo: '',
			descricao: '',
			ativo: true,
		},
		caracteristica: {
			id: null,
			codigo: '',
			descricao: '',
			ativo: true,
		},
		regiaoPrecificacao: null,
		statusUnidade: {
			id: null,
			codigo: '',
			descricao: '',
			ativo: true,
		},
		tipoFaturamento: {
			id: null,
			codigo: '',
			descricao: '',
			valorAutonomiaDesconto: 0,
			valorProporcionalMensalidade: 0,
			valorCobradoPagamento: 0,
			ativo: true,
		},
		tipoUnidade: {
			id: null,
			codigo: '',
			descricao: '',
			ativo: true,
		},
		cluster: {
			id: null,
			codigo: '',
			descricao: '',
			ativo: true,
		},
		endereco: {
			id: null,
			cep: '',
			//pais: "",
			nomeUF: '',
			paisId: null,
			uf: '',
			cidade: '',
			bairro: '',
			logradouro: '',
			numero: '',
			complemento: '',
			referencia: '',
			latitude: null,
			longitude: null,
			parent: 0,
			ativo: true,
		},
		gestor: {
			id: null,
			cpf: '',
			rg: '',
			email: '',
			descricaoEmpresa: '',
			observacao: '',
			descricaoProfissao: '',
			descricaoRestricao: '',
			dataNascimento: undefined,
			dataAdmissao: undefined,
			permiteUsoImagem: false,
			identificadorPagarMe: '',
			nome: '',
			ativo: true,
			identificadorPagarMePos: '',
		},
		responsavelLegal: {
			id: null,
			cpf: '',
			rg: '',
			email: '',
			descricaoEmpresa: '',
			observacao: '',
			descricaoProfissao: '',
			descricaoRestricao: '',
			dataNascimento: undefined,
			dataAdmissao: undefined,
			permiteUsoImagem: false,
			identificadorPagarMe: '',
			nome: '',
			ativo: true,
			identificadorPagarMePos: '',
		},
		taxaCobrancas: null,
		cursoUnidades: [],
		negocioUnidades: [],
		ofertas: [],
		unidadeDocumentos: [],
		unidadePessoas: [],
		//taxaCobrancas: [],
		identificadorPagarmePos: '',
		socio: null,
	} as IUnidadeFullDTO,
	revisao: false,
	// upload2: [
	//   {
	//     file: '',
	//     tipo: ''
	//   }
	// ],
	upload: {
		CONTRATO: {
			files: [],
		},
		CERT_NEGATIVA_DEBITO: {
			files: [],
		},
		CERT_NEGATIVA_PREFEITURA: {
			files: [],
		},
		CERT_DIST_FISC_CIV_TRAB_CRI: {
			files: [],
		},
		CERT_PROTESTO: {
			files: [],
		},
		ANT_CRIMINAIS: {
			files: [],
		},
		DECLA_IMP: {
			files: [],
		},
		LAUDO_BOMB: {
			files: [],
		},
	},
	// upload: [
	//   {
	//     documento: UploadUnidadeDoc.CONTRATO,
	//     file: null
	//   },
	//   {
	//     documento: UploadUnidadeDoc.CERT_NEGATIVA_DEBITO,
	//     file: null
	//   },
	//   {
	//     documento: UploadUnidadeDoc.CERT_NEGATIVA_PREFEITURA,
	//     file: null
	//   },
	//   {
	//     documento: UploadUnidadeDoc.CERT_DIST_FISC_CIV_TRAB_CRI,
	//     file: null
	//   },
	//   {
	//     documento: UploadUnidadeDoc.CERT_PROTESTO,
	//     file: null
	//   },
	//   {
	//     documento: UploadUnidadeDoc.ANT_CRIMINAIS,
	//     file: null
	//   },
	//   {
	//     documento: UploadUnidadeDoc.DECLA_IMP,
	//     file: null
	//   },
	//   {
	//     documento: UploadUnidadeDoc.LAUDO_BOMB,
	//     file: null
	//   },
	// ],
	setUnidade: () => {}, //função de inicialização
	removeUnidade: () => {}, //função de inicialização
	setRevisao: () => {}, //função de inicialização
	setUpload: () => {}, //função de inicialização
};

//criando nosso contexto UserContext
const UnidadeContext = createContext<PropsUnidadeContext>(DEFAULT_VALUE);
const nameStorage = '@HappyCode/unidade';
const nameStorageRevisao = '@HappyCode/unidade/revisao';
const nameStorageUpload = '@HappyCode/unidade/upload';
/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const UnidadeContextProvider: React.FC = ({ children }: any) => {
	const [unidade, setUnidadeGlobal] = useState<IUnidadeFullDTO>(DEFAULT_VALUE.unidade);
	const [revisao, setRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.revisao);
	const [upload, setUploadGlobal] = useState<any>(DEFAULT_VALUE.upload);

	const setRevisao = (revisao) => {
		setRevisaoGlobal(revisao);
		setObject(nameStorageRevisao, revisao);
	};

	const setUnidade = (unidade) => {
		setUnidadeGlobal(unidade);
		setObject(nameStorage, unidade);
	};

	const removeUnidade = () => {
		setRevisao(false);
		setUploadGlobal(DEFAULT_VALUE.upload);
		setUnidadeGlobal(DEFAULT_VALUE.unidade);
		removeKey(nameStorage);
	};

	const setUpload = (upload) => {
		setUploadGlobal(upload);
		setObject(nameStorageUpload, upload);
	};

	useEffect(() => {
		const unidade = getObject(nameStorage);
		if (unidade) {
			setUnidadeGlobal(unidade);
		}
		const revisao = getObject(nameStorageRevisao);
		if (revisao) {
			setRevisaoGlobal(revisao);
		}

		const upload = getObject(nameStorageUpload);
		if (upload) {
			setUploadGlobal(upload);
		}
	}, []);

	return (
		<UnidadeContext.Provider
			value={{
				unidade,
				revisao,
				upload,
				setUnidade,
				removeUnidade,
				setRevisao,
				setUpload,
			}}
		>
			{children}
		</UnidadeContext.Provider>
	);
};

export const useUnidadeContext = (): PropsUnidadeContext => {
	const context = useContext(UnidadeContext);
	if (!context) throw new Error('useUnidadeContext must be used within a UnidadeContextProvider');
	return context;
};
