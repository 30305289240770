import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import { Button, DatePicker, Image, Input, InputNumber, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import '../../../src/global.css';
import Header from '../../components/Header';
import './style.css';

import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { IBannerCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useBannerService } from '../../services/banner.service';
import { useNotificationService } from '../../services/notification.service';
import { formatMomentLocalDateTime, toMoment } from '../../util/format';
import { token } from '../../util/store';

const BannerCreate: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();
	const bannerService = useBannerService();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const [banner, setBanner] = useState<IBannerCleanDTO>();
	const [predicate] = useState<Predicate>(new Predicate());
	const { idBanner } = useParams<any>();

	const findBanner = () => {
		setIsGlobalLoading(true);
		bannerService
			.findCleanById({ id: idBanner })
			.then((response) => {
        
				setBanner(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {    
		if (idBanner) {
			findBanner();
		}
		return;
	}, []);

	const submit = () => {
		setIsGlobalLoading(true);
		if (!idBanner) {
			bannerService
				.create(banner)
				.then(() => {
					notification({
						description: `Banner cadastrado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/banner-learn');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		} else {
			bannerService
				.patch(banner)
				.then(() => {
					notification({
						description: `Banner editado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/banner-learn');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const submitValidationFields = () => {
		if (!banner?.nome) {
			notification({
				description: `Por favor, preencha o campo nome do banner.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!banner?.link) {
			notification({
				description: `Por favor, preencha o campo link do banner.`,
				type: 'warning',
				message: 'Atenção!',
			});
      return
    }
      if (!banner?.ordem) {
        notification({
          description: `Por favor, preencha o campo ordem do banner.`,
          type: 'warning',
          message: 'Atenção!',
        });
        return
      }
        if (!banner?.dataExpiracao) {
          notification({
            description: `Por favor, preencha o campo data de expiração do banner.`,
            type: 'warning',
            message: 'Atenção!',
          });
          return
        }	
        if (!banner?.imagem) {
          notification({
            description: `Por favor, preencha o campo imagem do banner.`,
            type: 'warning',
            message: 'Atenção!',
          });
        return;
		}
		submit();
	};

	const onChange = ({ file }: UploadChangeParam) => {
		const { status } = file;

		if (status === 'done') {
			notification({
				description: `${file.name} upload feito com sucesso!`,
				type: 'success',
				message: 'Sucesso!',
			});
			const response = file.response;
			setBanner({ ...banner, imagem: response });
		} else if (status === 'error') {
			notification({
				description: `${file.name} falha ao fazer o upload do arquivo`,
				type: 'error',
				message: 'Erro!',
			});
		} else if (status === 'removed') {
			setBanner({ ...banner, imagem: null });
		}
	};

	const beforeUpload = (file: UploadFile) => {
		const isValid =
			file !== undefined && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/webp');
		if (!isValid) {
			file.status = 'error';
			notification({
				description: `Apenas arquivos PNG, JPG e WEBP são permitidos.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}
		if (file.size > 5 * 1024 * 1024) {
			file.status = 'error';
			notification({
				description: `O tamanho do arquivo deve ser inferior a 5MB.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}
		return isValid;
	};
	return (
		<div className="container-fondo">
			<Header />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row">
					<div className="card-form">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira informações para {idBanner ? 'edição' : 'cadastro'} do banner
							</h3>
							<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
						</div>
						<div className="input-box">
							<label>Nome do Banner</label>
							<Input
								value={banner && banner?.nome}
								id="Nome"
								maxLength={100}
								placeholder="Ex.: Banner de promoção"
								onChange={(e) => setBanner({ ...banner, nome: e.target.value })}
							/>
						</div>
						<div className="input-box">
							<label>Link do redirecionamento</label>
							<Input
								value={banner?.link}
								id="insignia"
								onChange={(e) => setBanner({ ...banner, link: e.target.value })}
								placeholder="Ex.: www.exemplo.com"
							/>
						</div>
            <div className="input-box">
							<label>Data de vencimento</label>
              <DatePicker
										id="dataVencimento"
										disabledDate={(d) => d.isBefore(new Date(), 'day')}
										onChange={(e) =>
											setBanner((prev) => {
												return { ...prev, dataExpiracao: formatMomentLocalDateTime(e) };
											})
										}
										style={{ width: '100%' }}
										placeholder="Ex.: 01/01/2022"
										format="DD/MM/YYYY"
										value={toMoment(banner?.dataExpiracao)}
									/>
						</div>
						<div className="input-box">
							<label>Número da ordem</label>
							<InputNumber
								value={banner?.ordem}
								id="insignia"
								min={0}
								onChange={(e) => setBanner({ ...banner, ordem: e })}
								placeholder="Ex.: 1"
							/>
						</div>
						<div className="input-box" style={{ minWidth: '100%', minHeight: '100px' }}>
							<label>Imagem do banner</label>
							<div style={{ textAlign: 'center', width: '100%' }}>
								<div>{banner?.imagem && <Image src={banner?.imagem} alt="Ícone da medalha" width={200} />}</div>
								<Upload
									headers={{ Authorization: `Bearer ${token()}` }}
									action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload/link`}
									onChange={onChange}
									maxCount={1}
									beforeUpload={beforeUpload}
								>
									<Button
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											marginBottom: '1rem',
										}}
									>
										<UploadOutlined style={{ fontSize: '20px', marginBottom: '4px' }} />
										<span>Clique para fazer upload da imagem</span>
									</Button>
								</Upload>
							</div>
						</div>
						<div className="botoes" style={{ marginTop: 10 }}>
							<Link to={`/banner-learn`}>
								<Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
									Voltar
								</Button>
							</Link>
							<Button style={{ width: 345 }} type="primary" id="proximo" className="button-primary" onClick={submitValidationFields}>
								Salvar
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/banner-learn`}
			/>
		</div>
	);
};

export default BannerCreate;