import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';

import { IBrasaoDTO, ILogCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useBrasaoService } from '../../services/brasao.service';
import { useLogService } from '../../services/log.service';
import FiltrosBrasao from './filtros-brasao';
import ItemListBrasao from './item-lista';
import './style.css';

export const Brasao: React.FunctionComponent = () => {
	const [data, setData] = useState<PageableResponse<IBrasaoDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
	const brasaoService = useBrasaoService();
	const { setIsGlobalLoading } = useGlobalContext();

	const history = useHistory();
	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso Curso sede',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	useEffect(() => {
		logService.registraLog(log);
	}, []);

	const findBrasao = () => {
		setIsGlobalLoading(true);
		brasaoService
			.find(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findBrasao();
	};


	const ordenar = (campo) => {
		predicate.addSort(campo);
		findBrasao();
	};
	return (
		<div className="container">
			<NewMenu selecionado={204} />
			<div className="dashboard" style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}>
				<div className="box-title">
					<h3 className="title">Brasões ({data ? data?.totalElements : 0})</h3>
					<Button className="button-primary" id="criar-brasao" icon={<PlusOutlined rev={undefined} />} onClick={() => history.push('brasao/create')}>
						Novo Brasão
					</Button>
				</div>
				<FiltrosBrasao findBrasao={findBrasao} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '20%' }}>
							<span>ID</span>
							<div className="arrows" onClick={() => ordenar('id')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '30%' }}>
							<span>BRASÃO</span>
							<div className="arrows" onClick={() => ordenar('nome')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '17%' }}>
							<span>RECOMPENSA (COINS)</span>
							<div className="arrows" onClick={() => ordenar('coins')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '20%' }}>
							<span>INSÍGNIAS DISPONÍVEIS</span>
							<div className="arrows" onClick={() => ordenar('insignia')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
					</div>
					<div className="tabela-body">{data && data.content.map((brasao) => <ItemListBrasao key={brasao.id} brasao={brasao} />)}</div>
					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
