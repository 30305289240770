import http from '../helpers/http-common';
import { IHabilidadeLearnCleanDTO, IHabilidadeLearnDTO, IHabilidadeLearnFullDTO } from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useHabilidadeService = () => {
	const resourceURL = '/learn/v1/tbhabilidade';

	return {
		...baseFind<IHabilidadeLearnCleanDTO, IHabilidadeLearnDTO, IHabilidadeLearnFullDTO>(resourceURL),
		...baseFindList<IHabilidadeLearnCleanDTO, IHabilidadeLearnDTO, IHabilidadeLearnFullDTO>(resourceURL),
		...baseFindById<IHabilidadeLearnCleanDTO, IHabilidadeLearnDTO, IHabilidadeLearnFullDTO>(resourceURL),
		...baseCreate<IHabilidadeLearnCleanDTO, IHabilidadeLearnDTO, IHabilidadeLearnFullDTO>(resourceURL),
		...baseUpdate<IHabilidadeLearnCleanDTO, IHabilidadeLearnDTO, IHabilidadeLearnFullDTO>(resourceURL),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<IHabilidadeLearnCleanDTO>> => http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IHabilidadeLearnCleanDTO>> => http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
	};
};
