import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import { Button, Image, Input, InputNumber, Select, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import '../../../src/global.css';
import Header from '../../components/Header';
import './style.css';

import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import {
	IMedalhaDTO,
	INegocioLearnCleanDTO,
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useNotificationService } from '../../services/notification.service';
import { useMedalhasService } from '../../services/medalhas.service';
import { useNegocioLearnService } from '../../services/negocio-learn.service';
import { token } from '../../util/store';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

const MedalhaCreate: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();
	const medalhaService = useMedalhasService();
	const negocioService = useNegocioLearnService();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const [medalha, setMedalha] = useState<IMedalhaDTO>();
	const [predicate] = useState<Predicate>(new Predicate());
	const { idMedalha } = useParams<any>();
	const [negocios, setNegocios] = useState<INegocioLearnCleanDTO[]>([]);

	const findMedalha = () => {
		setIsGlobalLoading(true);
		medalhaService
			.findById({ id: idMedalha })
			.then((response) => {
				setMedalha(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findNegocio = () => {
		setIsGlobalLoading(true);
		negocioService
			.findList(predicate)
			.then((response) => {
				setNegocios(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		if (idMedalha) {
			findMedalha();
		}
		findNegocio();
		return;
	}, []);
	const submit = () => {
		setIsGlobalLoading(true);
		if (!idMedalha) {
			medalhaService
				.create(medalha)
				.then(() => {
					notification({
						description: `Medalha cadastrada com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/medalhas');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		} else {
			medalhaService
				.patch(medalha)
				.then(() => {
					notification({
						description: `Medalha editada com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/medalhas');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const submitValidationFields = () => {
		if (!medalha?.nome) {
			notification({
				description: `Por favor, preencha o campo nome da medalha.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!medalha?.negocio) {
			notification({
				description: `Por favor, preencha o campo negócio.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!medalha.icone) {
			notification({
				description: `Por favor, adicione um ícone da medalha.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		submit();
	};

	const onChange = ({ file }: UploadChangeParam) => {
		const { status } = file;

		if (status === 'done') {
			notification({
				description: `${file.name} upload feito com sucesso!`,
				type: 'success',
				message: 'Sucesso!',
			});
			const response = file.response;
			setMedalha({ ...medalha, icone: response });
		} else if (status === 'error') {
			notification({
				description: `${file.name} falha ao fazer o upload do arquivo`,
				type: 'error',
				message: 'Erro!',
			});
		} else if (status === 'removed') {
			setMedalha({ ...medalha, icone: null });
			notification({
				description: `${file.name} removido com sucesso!`,
				type: 'success',
				message: 'Sucesso!',
			});
		}
	};

	const beforeUpload = (file: UploadFile) => {
		const isValid =
			(file !== undefined && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/x-icon')) || file.type === 'image/webp';
		if (!isValid) {
			file.status = 'error';
			notification({
				description: `Apenas arquivos PNG, JPG e ICO são permitidos.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}
		if (file.size > 5 * 1024 * 1024) {
			file.status = 'error';
			notification({
				description: `O tamanho do arquivo deve ser inferior a 5MB.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}
		return isValid;
	};

	return (
		<div className="container-fondo">
			<Header />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row">
					<div className="card-form">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira informações para cadastro de medalha
							</h3>
							<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
						</div>
						<div className="input-box">
							<label>Nome da medalha</label>
							<Input
								defaultValue={medalha?.nome}
								value={medalha?.nome}
								id="medalha"
								maxLength={100}
								placeholder="Ex.: Gold"
								onChange={(e) => setMedalha({ ...medalha, nome: e.target.value })}
							/>
						</div>
						<div className="input-box">
							<label>Negócio</label>
							<Select
								style={{ width: '100%' }}
								id="negocio"
								placeholder="Selecione"
								allowClear
								showSearch
								defaultValue={medalha?.negocio?.id}
								value={medalha?.negocio?.id}
								onChange={(e) =>
									setMedalha({
										...medalha,
										negocio: { ...medalha?.negocio, id: e },
									})
								}
								filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
							>
								{negocios &&
									negocios.map((negocio) => (
										<Select.Option key={negocio.id} value={negocio.id} text={negocio.descricao}>
											{negocio.descricao}
										</Select.Option>
									))}
							</Select>
						</div>
						<div className="input-box">
							<label>Recompensa (coins)</label>
							<InputNumber defaultValue={medalha?.coins} value={medalha?.coins} placeholder="Ex.: 10" onChange={(e) => setMedalha({ ...medalha, coins: e })} />
						</div>
						<div className="input-box" style={{ minWidth: '100%', minHeight: '100px' }}>
							<label>Ícone</label>
							<div style={{ textAlign: 'center', width: '100%' }}>
								<div>{medalha?.icone && <Image src={medalha?.icone} alt="Ícone da medalha" width={200} />}</div>
								<Upload
									headers={{ Authorization: `Bearer ${token()}` }}
									action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload/link`}
									onChange={onChange}
									maxCount={1}
									beforeUpload={beforeUpload}
								>
									<Button
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											marginBottom: '1rem',
										}}
									>
										<UploadOutlined style={{ fontSize: '20px', marginBottom: '4px' }} />
										<span>Clique para fazer upload da imagem</span>
									</Button>
								</Upload>
							</div>
						</div>
						<div className="botoes" style={{ marginTop: 10 }}>
							<Link to={`/medalhas`}>
								<Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
									Voltar
								</Button>
							</Link>
							<Button style={{ width: 345 }} type="primary" id="proximo" className="button-primary" onClick={submitValidationFields}>
								Salvar
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/medalhas`}
			/>
		</div>
	);
};

export default MedalhaCreate;
