import "./styles.css";

interface Props {
    className?: string;
}

const Line: React.FunctionComponent<Props> = ({ className }: Props) => {
    return (
        <hr className={className ? className : "line-relatorio"} />
    );
}

export default Line;