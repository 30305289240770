import http from '../helpers/http-common';
import { IModuloLearnCleanDTO, IModuloLearnDTO, IModuloLearnFullDTO } from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useModuloLearnService = () => {
	const resourceURL = '/learn/v1/tbmodulo';

	return {
		...baseFind<IModuloLearnCleanDTO, IModuloLearnDTO, IModuloLearnFullDTO>(resourceURL),
		...baseFindList<IModuloLearnCleanDTO, IModuloLearnDTO, IModuloLearnFullDTO>(resourceURL),
		...baseFindById<IModuloLearnCleanDTO, IModuloLearnDTO, IModuloLearnFullDTO>(resourceURL),
		...baseCreate<IModuloLearnCleanDTO, IModuloLearnDTO, IModuloLearnFullDTO>(resourceURL),
		...baseUpdate<IModuloLearnCleanDTO, IModuloLearnDTO, IModuloLearnFullDTO>(resourceURL),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<IModuloLearnCleanDTO>> => http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IModuloLearnCleanDTO>> => http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
	};
};
