import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IConteudoLearnFullDTO } from '../../models/happy-code-api.model';
import { useGlobalContext } from '../../context/GlobalContext';
import { useNotificationService } from '../../services/notification.service';
import { useConteudoService } from '../../services/conteudo.service';

interface TItemLista {
	conteudo: IConteudoLearnFullDTO;
}

const ItemListConteudo = memo(({ conteudo }: TItemLista) => {
	const [ativo, setAtivo] = useState<boolean>();
	const history = useHistory();
	const notification = useNotificationService();
	const { setIsGlobalLoading } = useGlobalContext();
	const conteudoService = useConteudoService();

	const goTo = () => {
		history.push(`/conteudos/${conteudo.id}/create`);
	};

	useEffect(() => {
		setAtivo(conteudo.ativo);
	}, [conteudo.ativo]);

	const showDeleteConfirm = () => {
		Modal.confirm({
			title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} o conteúdo?`,
			icon: <ExclamationCircleTwoTone rev={undefined} />,
			content: conteudo.conteudo,
			okText: 'Sim',
			okType: `${ativo ? 'danger' : 'primary'}`,
			cancelText: 'Não',
			onOk: () =>
				conteudoService
					.patch({ id: conteudo.id, ativo: !ativo })
					.then(() => {
						setAtivo(!ativo);
						notification({
							description: 'Conteúdo alterado com sucesso!',
							type: 'success',
							message: 'Sucesso',
						});
					})
					.finally(() => setIsGlobalLoading(false)),
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onCancel: () => {},
		});
	};

	const menu = (
		<Menu>
			<Menu.Item onClick={showDeleteConfirm}>
				<a rel="noopener noreferrer">Suspender/Ativar</a>
			</Menu.Item>
		</Menu>
	);
	return (
		<div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
			<div className="user" id="cursos" style={{ width: '10%' }}>
				<div className="nome">
					<p>{conteudo?.id}</p>
					{ativo ? (
						<div>
							<div className="estado-user">
								<div className="dot-verde"></div>
								<p className="ativo">Ativo</p>
							</div>
						</div>
					) : (
						<div>
							<div className="estado-user">
								<div className="dot-vermelho"></div>
								<p className="inativo">Inativo</p>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="funcao-user" id="conteudo-id" style={{ width: '20%', textAlign: 'center' }}>
				<p>{conteudo?.conteudo}</p>
			</div>

			<div className="funcao-user" id="modulo-id" style={{ width: '15%' }}>
				<p>{conteudo?.modulo?.descricao}</p>
			</div>
			{/* <div className="funcao-user" id="cursos-id" style={{ width: '14%' }}>
				<p>teste</p>
			</div> */}
			<div className="funcao-user" id="curso-id" style={{ width: '15%' }}>
				<p>{conteudo?.curriculo?.nome}</p>
			</div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '15%' }}>
				<p>{conteudo?.negocio?.descricao}</p>
			</div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '15%' }}>
				<p>{conteudo?.tipoConteudo?.descricao}</p>
			</div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '5%' }}>
				<Dropdown overlay={menu} placement="bottomRight" arrow>
					<Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
				</Dropdown>
			</div>

			<div className="registro-opcao" style={{ width: '5%' }} onClick={goTo}>
				<i className="row-user__action-cell">
					<Unicons.UilAngleRight size="32" className="action-cell__action-icon" />
				</i>
			</div>
		</div>
	);
});

ItemListConteudo.displayName = 'ItemListConteudo';

export default ItemListConteudo;
