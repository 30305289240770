import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';

export const useFalaComandanteService = () => {
    const resourceURL = '/fala-comandante';

    const sendFeedback = async (dto): Promise<AxiosResponse<string>> => {
        return http.post(`${resourceURL}/send`, dto);
    };

    return { sendFeedback }; 
};
