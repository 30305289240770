import { DatePicker, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import NewMenu from '../../../components/NewMenu';
import { IUnidadeCleanDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { ReportColumns } from '../../../models/relatorio.model';
import { useRelatorioService } from '../../../services/relatorio.service';
import { useUnidadeService } from '../../../services/unidade.service';
import { ExportToExcel } from '../../../util/exportToExcel';
import moment from 'moment';
import { hoje } from '../../../util/util';

const { Option } = Select;

function RelExelenciaNotaTotal() {
	const [dados, setData] = useState<[]>();
	const [columns, setColumns] = useState<ReportColumns[]>();
	const [unidades, setUnidades] = useState<IUnidadeCleanDTO[]>();
	const [unidadeId, setUnidadeId] = useState();
	const [ano, setAno] = useState<number>(hoje().getFullYear());
	const [mes, setMes] = useState<number>(hoje().getMonth());

	const service = useRelatorioService();
	const unidadeService = useUnidadeService();

	useEffect(() => {
		const predicate = new Predicate();

		unidadeId && predicate.addOption('unidadeId', unidadeId);
		ano && predicate.addOption('ano', ano);
		mes && predicate.addOption('mes', mes);

		service.relatorioExelenciaNotaTotal(predicate)
			.then(({ data }) => {
				setData(data);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidadeId, ano, mes]);

	const fetchUnidades = () => {
		return unidadeService
			.findCleanList()
			.then((response) => setUnidades(response.data));
	};

	useEffect(() => {
		fetchUnidades();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidadeId]);

	useEffect(() => {
		if (dados && dados.length > 0) {
			setColumns(dados[dados.length - 1]['colunas']);
			dados.pop();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dados]);

	const selectUnidade = unidades?.map((unidade) => (
		<Option key={unidade.id} value={unidade.id} text={unidade?.descricao}>{unidade.descricao}</Option>
	));
	const filtroStyle = { minWidth: 200, border: '1px solid gainsboro' };

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h1 className="box-title" style={{ fontWeight: 'bold' }}>
					Relatório Excelência Nota Total
				</h1>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<div
					className="flex flex-row justify-between items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div>
						<span style={{ fontSize: '0.8em' }}>Unidade</span>
						<br />
						<Select
							style={filtroStyle}
							allowClear
							showSearch
							optionFilterProp="children"
							defaultValue={unidadeId}
							filterOption={(input, option) =>
								option?.text?.toLowerCase().includes(input.toLowerCase())}
							onChange={(e) => setUnidadeId(e)}
						>
							{selectUnidade}
						</Select>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Período vigente</span>
						<br />
						<DatePicker
							allowClear={true}
							placeholder={'Nova data'}
							size={"large"}
							picker="month"
							defaultValue={moment(`${ano}-${mes}`, 'YYYY-MM')}
							onChange={(e) => {
								setMes(e?.toDate()?.getMonth() + 1),
									setAno(e?.toDate()?.getFullYear())
							}}
						></DatePicker>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel
							apiData={dados}
							fileName={`Relatório Excelência - Nota Total ` + hoje().toISOString()}
						/>
					</div>
				</div>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<Table dataSource={dados} columns={columns} scroll={{ x: '100%' }} />
			</div>
		</div>
	);
}
export default RelExelenciaNotaTotal;
