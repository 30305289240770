import { useCallback, useEffect, useState } from 'react';
import useFindPowerBiDashboards from '../DashboardReport/use-find-powerbi-dashboards.hook';
import { ReportsOject } from '../../../types/reports-object.type';

export default function useReportsHeadOffice() {
	const relatorioSedeLink = '/relatorio-sede';

	const { buildDashboardDataToReportScreen } = useFindPowerBiDashboards();

	const [reportsHeadOffice, setReportsHeadOffice] = useState<ReportsOject[]>(
		[]
	);

	const initializeReportsData = useCallback((): ReportsOject[] => {
		return [
			{
				id: 1,
				title: 'Relatórios',
				reportCards: [
					// {
					// 	id: 2,
					// 	title: 'Matrículas não efetivadas',
					// 	link: `${relatorioSedeLink}/matricula-nao-efetivada`,
					// },
					// {
					// 	id: 3,
					// 	title: 'Financeiro Matrículas',
					// 	link: `${relatorioSedeLink}/financeiro-sede`,
					// },
					// {
					// 	id: 4,
					// 	title: 'Financeiro HUBs',
					// 	link: `${relatorioSedeLink}/financeiro-sede2`,
					// },
					// {
					// 	id: 5,
					// 	title: 'Cobranças Pagas',
					// 	link: `${relatorioSedeLink}/financeiro-pagos`,
					// },
					// {
					// 	id: 6,
					// 	title: 'Matriculas diárias',
					// 	link: `${relatorioSedeLink}/matricula-diaria-sede`,
					// },
					// {
					// 	id: 7,
					// 	title:
					// 		'Matrículas e Rematrículas - Novo Modelo Pré-Matriculado (Após 05/11)',
					// 	link: `${relatorioSedeLink}/matricula-diaria-sede-curriculo-prematriculado`,
					// },
					{
						id: 8,
						title: 'Relatório de Matrícula',
						link: `${relatorioSedeLink}/matricula`,
					},
					{
						id: 9,
						title: 'Relatório de Rematrícula',
						link: `${relatorioSedeLink}/rematricula`,
					},
					// {
					// 	id: 10,
					// 	title: 'Matrículas e Rematrículas - Novo Modelo (Após 05/11)',
					// 	link: `${relatorioSedeLink}/matricula-diaria-sede-curriculo`,
					// },
					{
						id: 11,
						title: 'Relatório Conciliação Bancária',
						link: `${relatorioSedeLink}/conciliacao-sede`,
					},
					{
						id: 12,
						title: 'Relatório inadimplência',
						link: `${relatorioSedeLink}/inadimplencia`,
					},
					{
						id: 13,
						title: 'Relatório Notas Fiscais',
						link: `${relatorioSedeLink}/notas-sede`,
					},
					{
						id: 14,
						title: 'Relatório Projeto 3k',
						link: `${relatorioSedeLink}/relatorio-3k`,
					},
					{
						id: 15,
						title: 'Relatório financeiro detalhado',
						link: `${relatorioSedeLink}/financeiro-detalhado-sede`,
					},
				],
			},
			{
				id: 15,
				title: 'Relatórios Escola',
				reportCards: [
					{
						id: 16,
						title: 'Relatório Contrato',
						link: `${relatorioSedeLink}/contrato-escola`,
					},
					{
						id: 17,
						title: 'Relatório Financeiro',
						link: `${relatorioSedeLink}/financeiro-escola`,
					},
					{
						id: 18,
						title: 'Relatório Evolução de Licença',
						link: `${relatorioSedeLink}/evolucao-licenca-escola`,
					},
					{
						id: 19,
						title: 'Relatório de Consolidação',
						link: `${relatorioSedeLink}/consolidacao-escola`,
					},
					{
						id: 20,
						title: 'Relatório Conciliação Bancária',
						link: `relatorio/conciliacao-escola`,
					},
				],
			},
			{
				id: 21,
				title: 'Relatórios Academy',
				reportCards: [
					{
						id: 22,
						title: 'Relatório Academy',
						link: `relatorio/progresso-trilha-instrutor-academy-sede`,
					},
				],
			},
			{
				id: 23,
				title: 'Programa de Excelência',
				reportCards: [
					{
						id: 24,
						title: 'Relatório Excelência Nota Total',
						link: `${relatorioSedeLink}/relatorio-exelencia-nota-total`,
					},
				],
			},
			{
				id: 25,
				title: 'Dashboards',
				reportCards: buildDashboardDataToReportScreen(),
			},
		];
	}, [buildDashboardDataToReportScreen]);

	const updateReportsHeadOffice = useCallback((): void => {
		const reports = initializeReportsData();

		setReportsHeadOffice(reports);
	}, [initializeReportsData]);

	useEffect(() => {
		const controller = new AbortController();

		updateReportsHeadOffice();

		return () => controller.abort();
	}, [updateReportsHeadOffice]);

	return {
		reportsHeadOffice,
	};
}
