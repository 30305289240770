import { differenceInDays } from "date-fns";
import { useEffect, useState } from "react";
import { IUnidadeCleanDTO } from "../../models/happy-code-api.model";

interface Props {
    unidade: IUnidadeCleanDTO;
}

export default function CertificadoComponent({ unidade }: Props) {
    const [daysRemaining, setdaysRemaining] = useState<number>();
    const [bgColor, setBgColor] = useState<string>();

    const checkForColor = (daysToExpire: number): string => {
        if (daysToExpire <= 0) return "bg-red";
        if (daysToExpire <= 60) return "bg-orange";
        return "bg-green";
    };

    const verifyDaysToExpire = (dtVencimento: string | undefined) => {
        if (!dtVencimento) return;

        const arrayDate = dtVencimento.split("/");
        const day = parseInt(arrayDate[0]);
        const month = parseInt(arrayDate[1]) - 1; 
        const year = parseInt(arrayDate[2]);

        const expirationDate = new Date(year, month, day);
        const today = new Date();

        const daysToExpire = differenceInDays(expirationDate, today);
        setdaysRemaining(daysToExpire);
        setBgColor(checkForColor(daysToExpire));
    };

    useEffect(() => {
        verifyDaysToExpire(unidade?.dtVencimento);
    }, [unidade]);

    return (
        <>
            <div className="digital-certificate" style={{ width: '25%' }}>
                {daysRemaining < 0 ? (
                    <>
                    <h4>O seu Certificado Digital está vencido há:</h4>
                    <h3 className={bgColor}>{daysRemaining.toString().substring(1)} dias</h3>
                   </>
                ): (
                   <>
                    <h4>O seu Certificado Digital irá vencer em:</h4>
                    <h3 className={bgColor}>{daysRemaining} dias</h3>
                   </>
                )}
                <h6 className={bgColor}>Vencimento: {unidade?.dtVencimento}</h6>
            </div>
        </>
    );
}
