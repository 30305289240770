import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { IHabilidadeLearnDTO } from '../../models/happy-code-api.model';
import { useHabilidadeService } from '../../services/habilidades.service';
import { useNotificationService } from '../../services/notification.service';

interface TItemLista {
	habilidade: IHabilidadeLearnDTO;
}

const ItemListHabilidades = memo(({ habilidade }: TItemLista) => {
	const [ativo, setAtivo] = useState<boolean>();

	const notification = useNotificationService();
	const history = useHistory();
	const { setIsGlobalLoading } = useGlobalContext();
	const habilidadeService = useHabilidadeService();
	const goTo = () => {
		history.push(`habilidades/${habilidade.id}/create`);
	};

	const showDeleteConfirm = () => {
		Modal.confirm({
			title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} a habilidade?`,
			icon: <ExclamationCircleTwoTone rev={undefined} />,
			content: habilidade.descricao,
			okText: 'Sim',
			okType: `${ativo ? 'danger' : 'primary'}`,
			cancelText: 'Não',
			onOk: () =>
				habilidadeService
					.patch({ id: habilidade.id, ativo: !ativo })
					.then(() => {
						setAtivo(!ativo);
						notification({
							description: 'Habilidade alterada com sucesso!',
							type: 'success',
							message: 'Sucesso',
						});
					})
					.finally(() => setIsGlobalLoading(false)),
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onCancel: () => {},
		});
	};
	useEffect(() => {
		setAtivo(habilidade?.ativo);
	}, [habilidade?.ativo]);

	const menu = (
		<Menu>
			<Menu.Item onClick={showDeleteConfirm}>
				<a rel="noopener noreferrer">Suspender/Ativar</a>
			</Menu.Item>
		</Menu>
	);

	return (
		<div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
			<div className="user" id="cursos" style={{ width: '20%' }}>
				<div className="nome">
					<p>{habilidade?.id}</p>
					{ativo ? (
						<div>
							<div className="estado-user">
								<div className="dot-verde"></div>
								<p className="ativo">Ativo</p>
							</div>
						</div>
					) : (
						<div>
							<div className="estado-user">
								<div className="dot-vermelho"></div>
								<p className="inativo">Inativo</p>
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="funcao-user" id="conteudo-id" style={{ width: '30%', textAlign: 'center' }}>
				<p>{habilidade?.descricao}</p>
			</div>
			<div className="funcao-user" id="conteudo-id" style={{ width: '20%' }}>
				<p>{habilidade?.coins}</p>
			</div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '20%' }}></div>

			<div className="funcao-user" id="cursos-modalidade" style={{ width: '10%' }}></div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '5%' }}>
				<Dropdown overlay={menu} placement="bottomRight" arrow>
					<Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
				</Dropdown>
			</div>
			<div className="registro-opcao" style={{ width: '5%' }} onClick={goTo}>
				<i className="row-user__action-cell">
					<Unicons.UilAngleRight size="32" className="action-cell__action-icon" />
				</i>
			</div>
		</div>
	);
});

ItemListHabilidades.displayName = 'ItemListHabilidades';

export default ItemListHabilidades;
