import http from '../helpers/http-common';
import {
	IModuloLearnFullDTO,
	INegocioLearnCleanDTO,
	INegocioLearnDTO,
	INegocioLearnFullDTO
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useNegocioLearnService = () => {
	const resourceURL = '/learn/v1/tbnegocio';



	return {
		...baseFind<INegocioLearnCleanDTO, INegocioLearnDTO, INegocioLearnFullDTO>(resourceURL),
		...baseFindList<INegocioLearnCleanDTO, INegocioLearnDTO, IModuloLearnFullDTO>(resourceURL),
		...baseFindById<INegocioLearnCleanDTO, INegocioLearnDTO, INegocioLearnFullDTO>(resourceURL),
		...baseCreate<INegocioLearnCleanDTO, INegocioLearnDTO, INegocioLearnFullDTO>(resourceURL),
		...baseUpdate<INegocioLearnCleanDTO, INegocioLearnDTO, INegocioLearnFullDTO>(resourceURL),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<INegocioLearnCleanDTO>> =>
			http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<INegocioLearnCleanDTO>> =>
			http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
	};
};
