import { IDashSectionCleanDTO, IDashSectionDTO, IDashSectionFullDTO } from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useDashSectionService = () => {
	const resourceURL = 'dash-section';

	return {
		...baseFindList<IDashSectionCleanDTO, IDashSectionDTO, IDashSectionFullDTO>(resourceURL),
	};
};
