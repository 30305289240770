import { Button, Modal } from 'antd';
import { IMensagemHomeDTO } from '../../models/happy-code-api.model';
import { setObject } from '../../util/store';
import { Fragment, useState } from 'react';

interface ModalProps {
	content: IMensagemHomeDTO[];
	open: boolean;
	setOpen: (open: boolean) => void;
}

export default function ModalMensagemHomeCarrousel({ content }: ModalProps) {
	const [closeModalIndividually, setCloseModalIndividually] = useState(true);
	const [index, setIndex] = useState<number>(0);

	const toCloseModal = () => {
		setCloseModalIndividually(false);
		setObject('modalLogin', false);
	};

	const verificarSeTemProximoIndice = () => {
		if (index < content.length - 1) {
			return true;
		}
		return false;
	};

	const verificarSeTemAnteriorIndice = () => {
		if (index > 0) {
			return true;
		}
		return false;
	};

	const nextMessage = () => {
		if (verificarSeTemProximoIndice()) {
			setIndex((prev) => prev + 1);
		} else {
			toCloseModal();
		}
	};

	const backMessage = () => {
		if (verificarSeTemAnteriorIndice()) {
			setIndex((prev) => prev - 1);
		}
	};

	return (
		<Fragment>
			{content && (
				<Modal
					style={{ top: '5%' }}
					mask
					closable={false}
					width={700}
					title={<div className="modal-title">{content[index].titulo}</div>}
					visible={closeModalIndividually}
					footer={
						<div>
							<Button key="submit" type="default" className="modal-footer-button" onClick={() => backMessage()}>
								Anterior
							</Button>
							<Button key="submit" type="primary" className="modal-footer-button" onClick={() => nextMessage()}>
								{verificarSeTemProximoIndice() ? 'Próximo' : 'Fechar'}
							</Button>
						</div>
					}
				>
					<div className="render-modal" dangerouslySetInnerHTML={{ __html: content[index].mensagem }} />
				</Modal>
			)}
		</Fragment>
	);
}
