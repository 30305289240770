import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import { IOperacoesPagarMeV4DTO } from '../models/relatorio.model';


export const usePagarMeV4Service = () => {
	const resourceURL = 'pagar-mev4';

	const operacoes = (
		recipientId: string, page: number, dataFim: number, dataInicio: number, count: number
	): Promise<AxiosResponse<IOperacoesPagarMeV4DTO[]>> =>
		http.get<IOperacoesPagarMeV4DTO[]>(
			resourceURL + `/operacao/${recipientId}/${page}/${dataInicio}/${dataFim}/${count}`
		);

		const operacoesNew = (
		recipientId: string, dataFim: string,dataInicio: string
	): Promise<AxiosResponse<IOperacoesPagarMeV4DTO[]>> =>
		http.get<IOperacoesPagarMeV4DTO[]>(
			resourceURL + `/operacao-new/${recipientId}/${dataInicio}/${dataFim}`
		);
		
	return { operacoes, operacoesNew };
};