
const useTranslate = () => {

    const translate = (description: string) => {
        switch (description) {
            case 'transfer':
                return 'Transferência';
            case 'processing':
                return 'Processando';
            case 'transferred':
                return 'Transferido';
            case 'available':
                return 'Disponível';
            case 'payment':
                return 'Pagamento';
            case 'payable':
                return 'A pagar';
            case 'paid':
                return 'Pago';
            case 'credit_card':
                return 'Cartão de crédito';
            case 'boleto':
                return 'Boleto';
            case 'pix':
                return 'Pix';
            default:
                return description;
        }
    };

    return {
        translate,
    };
};

export default useTranslate;
