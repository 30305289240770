import { QuestionCircleOutlined } from '@ant-design/icons';
import { DatePicker, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ModalLicencaHub from '../../../../components/ModalLicencaHub';
import ModalTaxaGateway from '../../../../components/ModalTaxaGateway';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { Predicate } from '../../../../models/predicate.model';
import { IRelFinanceiroDetalhado } from '../../../../models/relatorio.model';
import { RelFinanceiroDetalhadoTotalColumnKeys } from '../../../../models/types.model';
import { useRelatorioService } from '../../../../services/relatorio.service';
import { ExportToExcel } from '../../../../util/exportToExcel';
import { getPeriodoSemPadrao } from '../../../../util/filtroRelatorio';
import { formatMoney } from '../../../../util/format';
import TableColumns from './table-columns';
import { useDadosExportExcel } from './hooks/use-dados-export-excel.hook';
import { hoje } from '../../../../util/util';

const { RangePicker } = DatePicker;

function RelFinanceiroDetalhado() {
    const { setIsGlobalLoading, unidade } = useGlobalContext();
    const service = useRelatorioService();
    const [data, setData] = useState<IRelFinanceiroDetalhado[]>([]);
    const [dataVencimentoInicio, setDataVencimentoInicio] = useState<moment.Moment>(moment(hoje()));
    const [dataVencimentoFim, setDataVencimentoFim] = useState<moment.Moment>(moment(hoje()));
    const [isModalGatewayVisible, setIsModalGatewayVisible] = useState<boolean>(false);
    const [isModalLicencaVisible, setIsModalLicencaVisible] = useState<boolean>(false);
    const [filteredData, setFilteredData] = useState<IRelFinanceiroDetalhado[]>([]);

    const findRelatorio = () => {
        setIsGlobalLoading(true);
        const predicate = new Predicate();
        predicate.addOption('unidadeId', unidade?.id);
        dataVencimentoFim && predicate.addOption('vencimentoFim', dataVencimentoFim.toISOString());
        dataVencimentoInicio && predicate.addOption('vencimentoIni', dataVencimentoInicio.toISOString());
        service.financeiroDetalhado(predicate)
            .then(({ data }) => {
                const updatedData = [...data];
                updatedData.pop();

                setData(updatedData);
                setFilteredData(updatedData);
            }).finally(() => setIsGlobalLoading(false));
    }

    const totalColumns: RelFinanceiroDetalhadoTotalColumnKeys[] = [
        "C18_VL_TOTAL_OFERTA",
        "C19_VL_TOTAL_COM_DESCONTO",
        "C20_VL_OFERTA_HUB",
        "C21_VL_OFERTA_TEMPLO",
        "C22_VL_MENSALIDADE_COM_DESCONTO",
        "C23_VL_PARCELA",
        "C24_VL_ENCARGOS",
        "C25_VL_COBRANCA",
        "C26_VL_PAGO",
        "C27_VL_PARCELA_HUB",
        "C28_VL_PARCELA_LICENCA_TEMPLO"
    ];

    const calculateTotal = (key: keyof IRelFinanceiroDetalhado): number => {
        return data.reduce((sum, record) => {
            const value = parseFloat(record[key]?.toString()?.replace(',', '.') || '0');
            return sum + (isNaN(value) ? 0 : value);
        }, 0);
    };

    const getColumnTotal = (key: RelFinanceiroDetalhadoTotalColumnKeys): string => {
        const total = calculateTotal(key);
        return formatMoney(total);
    };

    const dadosExportExcel = useDadosExportExcel({ data, totalColumns, getColumnTotal });

    useEffect(() => {
        if (unidade?.id) {
            findRelatorio();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unidade?.id, dataVencimentoInicio, dataVencimentoFim]);

    return (
        <div className="container">
            <NewMenu selecionado={18} />
            <div
                className="dashboard"
                style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
            >
                <h1 className="box-title" style={{ fontWeight: 'bold' }}>
                    Relatório Financeiro Detalhado
                </h1>
                <div className="mb-5">
                    <div className='flex'>
                        <h1 className="mr-5">Taxa</h1>
                        <QuestionCircleOutlined rev={undefined} onClick={() => setIsModalGatewayVisible(true)} />
                    </div>
                    <ModalTaxaGateway
                        visible={isModalGatewayVisible}
                        title="STONE PAGAR.ME"
                        onClose={() => setIsModalGatewayVisible(false)}
                    />
                    {/* <TabelaTaxaGateway /> */}
                </div>
                <div className="mb-5">
                    <div className='flex'>
                        <h1 className="mr-5">Licença</h1>
                        <QuestionCircleOutlined rev={undefined} onClick={() => setIsModalLicencaVisible(true)} />
                    </div>
                    <ModalLicencaHub
                        visible={isModalLicencaVisible}
                        title="Licença "
                        unidade={unidade}
                        onClose={() => setIsModalLicencaVisible(false)}
                    />
                    {/* <TabelaTaxaGateway /> */}
                </div>
                <div className="flex flex-row justify-between items-center gap-10 mb-10">
                    <div>
                        <span style={{ fontSize: '0.8em' }}>Vencimento da parcela</span>
                        <br />
                        <RangePicker
                            onChange={(values) => {
                                setDataVencimentoInicio(values && values[0]);
                                setDataVencimentoFim(values && values[1]);
                            }}
                            value={getPeriodoSemPadrao(dataVencimentoInicio, dataVencimentoFim)}
                            format={'DD-MM-YYYY'}
                        />
                    </div>
                    <div>
                        <span style={{ fontSize: '0.8em' }}>Excel</span>
                        <br />
                        <ExportToExcel apiData={dadosExportExcel} fileName={'financeiros-detalhado ' + hoje().toISOString()} />
                    </div>
                </div>
                <hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
                <Table
                    dataSource={filteredData}
                    columns={TableColumns()}
                    scroll={{ x: '100%' }}
                    style={{ whiteSpace: "nowrap" }}
                    onChange={(_pagination, _filters, _sorter, extra) => {
                        setData(extra.currentDataSource);
                    }}
                    summary={() => (
                        <Table.Summary.Row>
                            {TableColumns().map((col, index) => {
                                if ('dataIndex' in col) {
                                    const key = col.dataIndex as RelFinanceiroDetalhadoTotalColumnKeys;
                                    return (
                                        <Table.Summary.Cell key={index} index={index} align="right">
                                            {totalColumns.includes(key) ? getColumnTotal(key) : ''}
                                        </Table.Summary.Cell>
                                    );
                                }
                                return <Table.Summary.Cell key={index} index={index} align="right" />;
                            })}
                        </Table.Summary.Row>
                    )}
                />
            </div>
        </div>
    );
}

export default RelFinanceiroDetalhado;
