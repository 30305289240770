import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Operators } from '../../enum/operators.enum';
import {
	IModalidadeCleanDTO,
	INegocioCleanDTO,
	ITipoCursoCleanDTO,
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useModalidadeService } from '../../services/modalidade.service';
import { useNegocioService } from '../../services/negocio.service';
import { useTipoCursoService } from '../../services/tipo-curso.service';

interface Props {
	predicate: Predicate;
	findConteudo: () => void;
}
const FiltrosConteudo = memo(({ predicate, findConteudo }: Props) => {
	const [descricao, setDescricao] = useState<string>();
	const [status, setStatus] = useState<number>();
	const [modalidadeId, setModalidadeId] = useState<number>();
	const [modalidades, setModalidades] = useState<IModalidadeCleanDTO[]>([]);
	const [tipoConteudoID, setTipoConteudoID] = useState<number>();
	const [tipoCursos, setTipoCursos] = useState<ITipoCursoCleanDTO[]>([]);
	const [negocioId, setNegocioId] = useState<number>();
	const [negocios, setNegocios] = useState<INegocioCleanDTO[]>([]);

	const serviceTipoCurso = useTipoCursoService();
	const serviceNegocio = useNegocioService();
	const serviceModalidade = useModalidadeService();

	useEffect(() => {
		serviceModalidade
			.findCleanList(new Predicate())
			.then(({ data }) => setModalidades(data));
		serviceTipoCurso
			.findCleanList(new Predicate())
			.then(({ data }) => setTipoCursos(data));
		serviceNegocio
			.findCleanList(new Predicate())
			.then(({ data }) => setNegocios(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const typingTimeout = setTimeout(() => {
			predicate.removeAllOption();
			if (descricao) {
				predicate.addOption('conteudo', descricao, Operators.CONTAINS);
			}
			if (status) {
				predicate.addOption('ativo', status == 1 ? true : false);
			}
			findConteudo();
		}, 800);
		return () => clearTimeout(typingTimeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [descricao, status, modalidadeId, tipoConteudoID, negocioId]);

	return (
		<>
			<div className="filtros">
				<div className="input-text" style={{ width: '90%' }}>
					<Input
						id="buscar"
						value={descricao}
						placeholder="Buscar conteúdo por título"
						prefix={
							<SearchOutlined style={{ color: '#6A7C96' }} rev={undefined} />
						}
						onChange={(e) => setDescricao(e.target.value)}
					/>
				</div>
				<div className="filtro-estado input-dashboard" style={{ width: '15%' }}>
					<Select
						placeholder="Status"
						className="ant-dropdown-link"
						style={{ width: '100%' }}
						onChange={(e: number) => setStatus(e)}
					>
						<Select.Option value={0}>Todos</Select.Option>
						<Select.Option key={1} value={1}>
							Ativo
						</Select.Option>
						<Select.Option key={2} value={2}>
							Inativo
						</Select.Option>
					</Select>
				</div>
			</div>
		</>
	);
});

FiltrosConteudo.displayName = 'FiltrosConteudo';

export default FiltrosConteudo;
